import React, { useState } from "react";
import PropTypes from 'prop-types';
import { List, ListItem } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Table, TableRow, TableBody, TableCell } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { grey } from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Chip, Icon} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { grayColor, aluminiumColor } from "../../assets/jss/material-dashboard-react.js";

const styles = {
  tableBody: {
    borderTop: "none",
    borderBottom: "none",
    borderCollapse: "collapse"
  },
  collapsedTableRow: {
    "&:last-of-type td": {
      borderBottom: "none"
    }
  },
  tableCell: {
    width: "100%",
    padding: "8px",
    borderTop: "none",
    "@media (max-width: 693px)": {
      paddingTop: 0,
      paddingBottom: 0
    },
    "& .emptyList": {
      display: "inline-block",
      padding: "8px",
      height: "24px",
      "@media (max-width: 445px)": {
        paddingTop: 0,
        paddingBottom: 0
      },
    }
  },
  collapsableTableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    borderTop: "none"
  },
  tableHead: {
    fontWeight: "bold", fontSize: "13px", padding: "8px", borderBottom: "none",
    "@media (max-width: 445px)": {
      paddingBottom: 0
    },
  },
  tagListRow: {
    display: 'flex', flexDirection: "row",
    "@media (max-width: 693px)": {
      "&:first-child": {
        borderBottom: "none"
      },
      "&:last-child": {
        borderBottom: "none"
      }
    },
    "@media (max-width: 445px)": {
      flexDirection: "column",
      padding: "0!important"
    },
  },
  tagList: {
    display: "flex", flexDirection: "row", justifyContent: "left", flexWrap: "wrap",
    "@media (max-width: 445px)": {
      paddingTop: 0
    },
  },
  listItem: {
    width: "auto", padding: "4px",
    "& div.MuiChip-outlined": {
      border: "none",
      backgroundColor: aluminiumColor,
      color: "#fff",
      fontSize: "12px",
      fontWeight: 600,
      paddingLeft: "4px"
    }
  },
  flexRow: {
    display: 'flex', flexDirection: "row", alignItems: "baseline"
  },
  chip: {
    "& .MuiChip-label": {
      "@media (max-width: 500px)": {
        padding: "0 8px"
      }
    }
  },
  actionIcon: {
    paddingLeft: 0,
    verticalAlign: "top",
    paddingRight: "3px"
  },
  collapsableBtn: {
    width: "12px",
    marginRight: "8px"
  },
  noLeftPadding: {
    paddingLeft: 0
  },
  noBorderBottom: {
    borderBottom: "none"
  },
  borderTop: {
    borderTop: "1px solid " + grayColor[5]
  },
};
const useStyles = makeStyles(styles);

export default function CollapsableRow(props) {
  const { classes, guest, handleClickForGuest, handleOpenRsvpDetails } = props;
  const [open, setOpen] = useState(false);
  const rowStyles = useStyles();

  return (
    <React.Fragment>
      <TableRow key={`collapsable-row-${guest.id}`} onClick={() => setOpen(!open)}>
        <TableCell className={`${classes.tableCell} ${rowStyles.noLeftPadding}`}>
          <div className={rowStyles.flexRow}>
            <div>
              <IconButton
                aria-label="expand row"
                size="small"
                className={rowStyles.collapsableBtn}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </div>
            <div>
              <span className={classes.cellTitle}>{guest.name}</span><br/>
              <span className={classes.cellText}>
                {guest?.numberOfPersons ? guest.numberOfPersons === 1 ? '1 persoană' : guest?.numberOfPersons + ' persoane' : ''}
                {guest?.tableNo ? ` la Masa ${guest.tableNo}` : ''}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell className={`${classes.tableCell} ${classes.pt14} ${rowStyles.chip}`} align="center">
          {
            {
              'accepted': <Chip style={{backgroundColor: "#88ca5f"}} icon={<SentimentVerySatisfiedIcon style={{ color: "#fff" }} />} label="Da" variant="outlined" onClick={guest.acceptedTerms ? () => handleOpenRsvpDetails(guest.id) : undefined} className={guest.acceptedTerms ? classes.coloredOutline : ''} />,
              'rejected': <Chip style={{backgroundColor: "#ec7878"}} icon={<SentimentVeryDissatisfiedIcon style={{ color: "#fff" }} />} label="Nu" variant="outlined" onClick={guest.acceptedTerms ? () => handleOpenRsvpDetails(guest.id) : undefined} className={guest.acceptedTerms ? classes.coloredOutline : ''} />
            }[guest.status] ||
            <Chip style={{backgroundColor: "#7c858d"}} icon={<HelpOutlineIcon style={{ color: "#fff" }} />} label="Pndg" variant="outlined" />
          }
        </TableCell>
        <TableCell className={classes.tableActions} align="right" onClick={(e) => e.stopPropagation()}>
          <Tooltip
            id="tooltip-top"
            title="Editează"
            placement="top"
            classes={{tooltip: classes.tooltip}}
          >
            <IconButton aria-controls="simple-menu" aria-haspopup="true"
                onClick={handleClickForGuest(guest.id)} className={rowStyles.actionIcon}>
              <MoreVertIcon/>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow style={{border: "none"}} key={`collapsable-tags-label-${guest.id}`} className={rowStyles.collapsedTableRow}>
        <TableCell className={rowStyles.collapsableTableCell} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table className={rowStyles.table}>
              <TableBody className={rowStyles.tableBody}>
                <TableRow className={`${classes.tableRow} ${rowStyles.tagListRow} ${rowStyles.borderTop}`} key={`collapsable-tbody-${guest.id}`}>
                  <TableCell variant="head" width={50} className={rowStyles.tableHead}>Grupuri</TableCell>
                  <TableCell className={rowStyles.tableCell} width={"auto"}>
                    {
                      guest.groupTags ? guest.groupTags.length ?
                        <List className={rowStyles.tagList}>
                        {guest.groupTags.map((tag) => {
                            return (
                              <ListItem key={`${tag.id}-${tag.name}`} className={rowStyles.listItem}>
                                <Chip
                                  key={tag.id}
                                  className={rowStyles.chip}
                                  icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                                  label={tag.name}
                                  variant="outlined"
                                />
                              </ListItem>
                            );
                          })}
                      </List> :
                      <span className="emptyList"> - </span>
                    : ""}
                  </TableCell>
                </TableRow>
                <TableRow className={`${classes.tableRow} ${rowStyles.tagListRow}`} key={`collapsable-trow-${guest.id}`}>
                  <TableCell variant="head" width={51} className={rowStyles.tableHead}>Etichete</TableCell>
                  <TableCell width={"auto"} className={`${rowStyles.tableCell} ${rowStyles.noBorderBottom}`}>
                    {
                      guest.individualTags ? guest.individualTags.length ?
                        <List className={rowStyles.tagList}>
                          {guest.individualTags.map((tag) => {
                            return (
                              <ListItem key={tag.id} className={rowStyles.listItem}>
                                <Chip
                                  key={tag.id}
                                  className={rowStyles.chip}
                                  icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                                  label={tag.name}
                                  variant="outlined"
                                />
                              </ListItem>
                            )
                          })}
                        </List> :
                        <span className="emptyList"> - </span>
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const tag = {
    id: PropTypes.number,
    name: PropTypes.string,
    iconCode: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
    isGroupTag: PropTypes.number,
    isIndividualTag: PropTypes.number
}

CollapsableRow.propTypes = {
  classes: PropTypes.any,
  guest: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    eventId: PropTypes.number,
    numberOfPersons: PropTypes.number,
    phone: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    groupTags: PropTypes.arrayOf(PropTypes.shape(tag)),
    individualTags: PropTypes.arrayOf(PropTypes.shape(tag)),
    tableNo: PropTypes.number,
    message: PropTypes.string,
    acceptedTerms: PropTypes.number
  }),
  handleClickForGuest: PropTypes.func,
  handleOpenRsvpDetails: PropTypes.func
}