import { red, grey } from "@material-ui/core/colors";
import {blackColor, infoColor, whiteColor} from "../../material-dashboard-react.js";

const styles = {
    root: {
        maxWidth: 340,
        marginBottom: '15px'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto'
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    cardContainer: {
        borderRadius: '4px',
        fontFamily: 'Poppins',
        backgroundColor: '#fff',
        padding: '20px',
        '& .home': {
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '40px',
            '& .noPadding': {
                paddingLeft: '0!important',
                paddingRight: '0!important'
            },
            '& .img': {
                width: '33.33%',
                flex: 1,
                '& .stats-img': {
                    width: '100%',
                    borderRadius: '4px'
                }
            },
            '& .home-stats': {
                padding: 0,
                margin: 0,
                width: '33.33%',
                '& ul': {
                    padding: 0
                },
                '& a': {
                    color: 'rgba(0, 0, 0, 0.87)'
                },
                '& a:first-of-type': {
                    paddingTop: 0
                },
                '& .MuiAvatar-colorDefault': { backgroundColor: '#7d858ccf' }
            },
            '& .cta': {
                flex: 1,
                textAlign: 'center',
                '& .invite-btn': {
                    margin: '0 5px',
                    '& button': {
                        width: '210px'
                    },
                    "&:hover": {
                        textDecoration: 'none'
                    }
                },
                '& .invite-btn.edit button': {
                    backgroundColor: whiteColor,
                    color: blackColor
                }
            },
            '@media (max-width: 1300px)': {
                '& .img': { 
                    minWidth: '50%',
                    marginBottom: '24px'
                },
                '& .home-stats': {width: '50%'}
            },
            '@media (max-width: 670px)': {
                '& .img': { 
                    width: '100%',
                    marginBottom: 0 
                },
                '& .home-stats': {
                    width: '100%',
                    margin: '24px 0'
                }
            }
            
        },
        '& hr': {
            margin: '20px 0',
            borderTop: `2px solid ${grey[300]}`,
            borderBottom: 0,
            borderLeft: 0,
            borderRight: 0,
        }
    },
    'gray-bg': {
        width: '100%',
        height: '100%',
        padding: '20px 0 5px 0',
        backgroundColor: grey[200],
        '& .MuiGrid-container': {
            '& .MuiPaper-root': {
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },
    cardCta: {
        color: infoColor[0],
        fontWeight: 'bold'
    }
};
export default styles;