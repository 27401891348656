import axios from "axios";

export const get = async (url) => {
    return await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${url}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            withCredentials: true
        }
    );
};
export const post = async (url, body) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}${url}`,
        body,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            withCredentials: true
        });
};
