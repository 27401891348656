import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// custom components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Dialog, DialogTitle, DialogContent, Accordion, AccordionDetails, DialogActions, TextField, Button, MenuItem, Table, TableBody } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker  } from "@material-ui/pickers";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import MoreVertIcon from '@material-ui/icons/MoreVert';
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";

const useStyles = makeStyles(styles);

export default function Todo(props) {
  const classes = useStyles();
  const { todo, handleTodoStatusChange, deleteTodoItem, editTodoItem, TodoStatus, TodoCategory } = props;
  const [status, setStatus] = useState(todo.status === "completed" ? true : false);
  const tableCellClasses = classnames(classes.tableCell);
  const [openEdit, setOpenEdit] = useState(false);
  const [updateTodo, setUpdateTodo] = useState(todo);
  const [anchorEl, setAnchorEl] = useState(null);
  const openTooltip = Boolean(anchorEl);

  //   Events
  const changeTodoStatus = () => {
    const newStatusIsChecked = !status;
    const newStatus = newStatusIsChecked === true ? "completed" : "open";
    handleTodoStatusChange(todo.id, newStatus);
    setStatus(newStatusIsChecked);
  }

  const handleTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteTodo = () => {
    deleteTodoItem(todo.id);
  }

  const handleEditTodoChange = (event) => {
    setUpdateTodo({
      ...updateTodo,
      [event.target.name]: event.target.value,
    });
  }

  const handleDueDateChange = (date) => {
    setUpdateTodo({
      ...updateTodo,
      dueDate: date
    })
  }

  const editTodoHandler = () => {
    editTodoItem(updateTodo);
    setStatus(updateTodo.status === "completed" ? true : false);
    setOpenEdit(false);
  }

  return (
    <React.Fragment>
      <TableRow className="todo-row">
        <TableCell colSpan={2}>
          <Table className={classes.todoTable}>
            <TableBody>
              <TableRow>
                <TableCell className="w0">
                  <Checkbox
                    checked={status}
                    tabIndex={-1}
                    onClick={() => changeTodoStatus()}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.root
                    }}
                  />
                </TableCell>
                <TableCell style={{}} className="description">
                  {todo.description}
                </TableCell>
                <TableCell className="w0">
                  <Tooltip
                    id="tooltip-top"
                    title="Acțiuni"
                    placement="top"
                    classes={{tooltip: classes.tooltip}}
                  >
                    <IconButton aria-controls="simple-menu" aria-haspopup="true"
                        onClick={handleTooltipClick}  >
                      <MoreVertIcon/>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="simple-menu"
                    keepMounted
                    open={openTooltip}
                    onClose={() => setAnchorEl(null)}
                    className={classes.editMenu}
                  >
                    <MenuItem onClick={() => {setOpenEdit(true); setAnchorEl(null);}} >
                      <ListItemIcon>
                        <Edit style={{ color: "rgba(0, 0, 0, 0.8)" }} />
                      </ListItemIcon>
                      <ListItemText primary="Editează" />
                    </MenuItem>
                    <DeleteButton buttonText="Șterge" 
                      confirmTitle="Șterge todo" 
                      confirmText={`Cu siguranță dorești ștergerea activității ${todo.description}?`}
                      confirmedDeleteHandler={deleteTodo}
                      modalOpenHandler={() => true}
                    />
                  </Menu>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>
                  <GridContainer classname="notes-row">
                    { todo.notes && <GridItem xs={12}><p className="notes">Note: {todo.notes}</p></GridItem>}
                    <GridItem>
                      <p>{new Date(todo.dueDate).toLocaleDateString('ro-RO')}</p>
                    </GridItem>
                  </GridContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
      
      {/* edit form */}
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title">Editează Todo</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Accordion className="dialog-content">
            <AccordionDetails className={classes.details}>
              <GridContainer>
                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                  <TextField fullWidth margin="normal" id="standard-basic" name="description" label="Descriere" value={updateTodo.description || ""} onChange={handleEditTodoChange} />
                </GridItem>
                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                  <TextField fullWidth margin="normal" id="standard-basic" name="notes" label="Note" value={updateTodo.notes || ""} onChange={handleEditTodoChange} />
                </GridItem>
                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      format="dd.MM.Y"
                      name="dueDate"
                      value={updateTodo.dueDate || Date.now()}
                      onChange={handleDueDateChange}
                      cancelLabel="Anulează"
                      label="Termen"
                      InputProps={{ readOnly: true }}
                      minDate={new Date("2021-01-01 00:00:00")}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                  <TextField
                    fullWidth
                    select
                    margin="normal"
                    label="Categorie"
                    name="category"
                    value={updateTodo.category}
                    onChange={handleEditTodoChange}
                  >
                    {
                      Object.values(TodoCategory).map(item => {
                        return (
                          <MenuItem key={item.category} value={item.category}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    }
                  </TextField>
                </GridItem>
                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                  <TextField
                    fullWidth
                    select
                    margin="normal"
                    label="Status"
                    name="status"
                    value={updateTodo.status}
                    onChange={handleEditTodoChange}
                  >
                    {
                      Object.values(TodoStatus).map(item => {
                        return (
                          <MenuItem key={item.status} value={item.status}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    }
                  </TextField>
                </GridItem>
              </GridContainer>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)}>Renunță</Button>
          <Button onClick={editTodoHandler} autoFocus>
            Salvează
          </Button>
        </DialogActions>
      </Dialog>
      {/* end of edit form */}
    </React.Fragment>
  );
}

Todo.propTypes = {
  todo: PropTypes.shape({
    id: PropTypes.any,
    description: PropTypes.string,
    notes: PropTypes.string,
    dueDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    category: PropTypes.string,
    status: PropTypes.string,
    eventId: PropTypes.number
  }),
  handleTodoStatusChange: PropTypes.func,
  deleteTodoItem: PropTypes.func
};