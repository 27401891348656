import React, { useState, useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles as any);

export const Wishlist: React.FC<{}> = (props) => {
  const classes = useStyles();
  const [invites, setInvites] = useState<string[][]>([]);
  useEffect(() => {
    setInvites([
      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
      ["4", "Philip Chaney", "$38,735", "Korea, South"]
    ]);
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
                <p className={classes.cardCategoryWhite}>
                  New employees on 15th September, 2016
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["Id", "Nume", "Salary", "Country"]}
                  tableData={invites}
                />
              </CardBody>
            </Card>
          </GridItem>
      </GridContainer>
    </div>
  );
}
