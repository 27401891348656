import React, { useState, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";

import {post} from "../../utils/OctoberApiUtils";
import '../../assets/css/login-register-styles.css';
import {UserActions, UserContext} from "../../contexts/UserContext";
import { set } from "date-fns";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  error: {
    color: '#d32f2f',
    backgroundColor: '#f2c2c2',
    padding: '12px',
    borderRadius: '4px',
    '& p': {
      margin: 0,
      fontSize: '14px'
    },
    '& a': {
      textDecoration: 'underline'
    }
  },
  fullwidth: {
    width: '100%'
  },
  footerParagraph: {
    display: 'flex',
    justifyContent: 'right',
    margin: '5px 20px 10px 0',
    padding: 0,
    color: 'rgba(0, 0, 0, 0.47)',
    fontSize: '12px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  mt80: {
    marginTop: '80px'
  }
};

const useStyles = makeStyles(styles);

export default function UserLogin() {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleEmailChange = e => {
    setLoginError("");
    setEmail(e.target.value);
  }

  const handlePasswordChange = e => {
    setLoginError("");
    setPassword(e.target.value);
  }

  const login = async () => {
    try {
      const response = await post('/api/react/auth/login', {email, password});
      if(response.data.userId>0){
        if(response.data.isMbeUser>0) {
          console.log('user login success');
          userContext.dispatch({ type: UserActions.USER_LOGIN, user: {id: response.data.userId}});

          console.log(`last route is ${location.state ? location.state.from : 'empty' }` );
          let { from } = location.state || { from: { pathname: "/" } };
          history.replace(from);
        } else {
          setLoginError('Nu ai niciun plan My Belle Event activat. <a href="https://my-invite.ro/planuri-my-belle-event?utm_source=MyBelleEventLogin">Vezi planuri</a>');
        }     
      } else {
        setLoginError('Adresa de email introdusă nu este asociată unui cont my-invite.ro.<br /><a href="https://my-invite.ro?utm_source=MyBelleEventLogin">Creează cont</a>')
      }
    } catch (error) {
      setLoginError('Adresa de email sau parola sunt incorecte.');
      console.log(`Cannot login, error`, error);
    }
  };

  const validateAndLogin = () => {
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!isEmail.test(email)) {
      setLoginError("Adresa de email nu este validă.");
    } else if(password.length == 0) {
      setLoginError("Parola este obligatorie.");
    } else {
      setLoginError("");
      login();
    }
  }

  return (
    <div className={`${classes.mt80} formContainer`}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} className={classes.grid}>
          <Card>
            <CardHeader color="black">
              <h4 className={classes.cardTitleWhite}>Login</h4>
              <p className={classes.cardCategoryWhite}>Intră în contul tău My Belle Event</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Adresa de email"
                    id="email-address"
                    value={email}
                    name="userLoginEmail"
                    onChange={handleEmailChange}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Parola"
                    id="password"
                    type="password"
                    name="userLoginPassword"
                    onChange={handlePasswordChange}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                {loginError && 
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.error}>
                      <p dangerouslySetInnerHTML={{ __html: loginError }}></p>
                    </div>
                  </GridItem>
                }
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button className={classes.fullwidth} color="black" onClick={validateAndLogin}>Intră in cont</Button>
            </CardFooter>
            {/* <p className={classes.footerParagraph}><a href="https://my-invite.ro">Fa-ti cont pe My-Belle-Event</a></p> */}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
