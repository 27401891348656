import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    notchedOutline: {
      borderWidth: "2px"
    },
    container: {
        "& .MuiInputBase-multiline" : {
            "@media (max-width: 420px)": {
                padding: "18.5px 10px"
            }
        }
    }
};
const useStyles = makeStyles(styles);

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label, classes }) => {
    const divClasses = useStyles();
    return (
        <TextField
            fullWidth
            className={`${classes} ${divClasses.container}`}
            variant="outlined"
            margin="normal"
            label={label}
            multiline
            InputLabelProps={{ shrink: true }}
            InputProps={{
                inputComponent: InputComponent, 
                classes: { notchedOutline: divClasses.notchedOutline }
            }}
            inputProps={{ children: children }}
        />
    );
};
export default OutlinedDiv;
