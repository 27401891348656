import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionDetails,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";

import { Table } from "../../types/Table";

const useStyles = makeStyles(styles as any);

export const AddTable: React.FC<any> = (props) => {
    const classes = useStyles();
    const { isDialogOpened, handleCloseDialog, tables, setTables, eventId, tableNumbers, tableColors} = props;
    const initialTable: Table = {title:"", description:"", tableNo: 1, icon: "", color: "black", numberOfGuests: 10, eventId:eventId, guests:[]};
    const [table, setTable] = useState<Table>(initialTable);
    const [availableTableNumbers, setAvailableTableNumers] = useState<number[]>([]);

    useEffect(() => {
        const currentTableNumbers = tables.map((table:Table) => table.tableNo);
        const availableNumbers = tableNumbers.filter((el:number) => !currentTableNumbers.includes(el));
        setAvailableTableNumers(availableNumbers);
        setTable({...table, tableNo: availableNumbers[0]});
    }, [tables]);

    const handleChange = (event: React.ChangeEvent<any>): void => {
        setTable({
            ...table,
            [event.target.name]: event.target.value,
        });
        console.log(table);
    }

    const addTableHandler = async () => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guestTable/add`, 
        {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
            },
            body: JSON.stringify(table) // body data type must match "Content-Type" header
        })
      .then(response => response.json())
      .then(responseJson => {
        const updatedTables = [...tables, responseJson];
        console.log('updated tables', updatedTables)
        setTables(updatedTables.sort((a, b) => a.tableNo - b.tableNo));
        setTable(initialTable);
        handleCloseDialog();
      })
      .catch(error => console.log('error'));
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isDialogOpened}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle id="alert-dialog-title">Adaugă masă</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Accordion className="dialog-content">
                        <AccordionDetails className={classes.details}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                                <TextField fullWidth margin="normal" id="standard-basic" name="title" label="Titlu" value={table?.title} onChange={handleChange} />
                            </GridItem>
                            <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                                <TextField fullWidth multiline margin="normal" id="standard-basic" name="description" label="Descriere" value={table?.description} inputProps={{ maxLength: 90 }} onChange={handleChange} />
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.dialogGridItem}>
                                <FormControl variant="standard" fullWidth margin="normal">
                                    <InputLabel id="demo-simple-select-standard-label">Culoare</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={table?.color}
                                        onChange={handleChange}
                                        name="color"
                                        label="Culoare"
                                    >
                                    {tableColors.map((tableColor: {code: string, title: string}) => <MenuItem key={tableColor.code} value={tableColor.code}>{tableColor.title}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <FormControl variant="standard" fullWidth margin="normal">
                                    <InputLabel id="demo-simple-select-standard-label">Nr. masă</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={table?.tableNo}
                                        onChange={handleChange}
                                        name="tableNo"
                                        label="Nr. masă"
                                    >
                                    {availableTableNumbers.map((tableNumber: number) => <MenuItem key={tableNumber} value={tableNumber}>{tableNumber}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <TextField id="standard-basic" name="numberOfGuests" label="Nr. persoane la masă" type="number" value={table?.numberOfGuests} onChange={handleChange} />
                            </GridItem>
                        </GridContainer>
                        </AccordionDetails>
                        <Divider />
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Renunță</Button>
                    <Button onClick={addTableHandler} autoFocus>Adaugă</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}