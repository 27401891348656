import React, {useState, useContext, useEffect} from "react";
import { EventsDataContext, EventActions } from "../../contexts/EventContext";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Card,
    CardContent,
    Typography,
    FormControl,
    MenuItem,
    Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";
import {get} from "../../utils/OctoberApiUtils";

const useStyles = makeStyles(styles as any);

export const ChangeEventModal: React.FC<any> = (props) => {
    const { isDialogOpened, handleCloseDialog} = props;
    const classes = useStyles();
    const eventsContext = useContext(EventsDataContext);
    const events = eventsContext.state.events;
    const [selectedEventId, setSelectedEventId] = useState(eventsContext.state.currentEvent?.id);

    useEffect(() => {
        setSelectedEventId(eventsContext.state.currentEvent?.id);
    }, [eventsContext.state.currentEvent?.id]);

    const handleClose = () => {
        handleCloseDialog(false);
    };

    const onOptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const eventId = event.target.value as number;
        setSelectedEventId(eventId);
    }

    const changeEventHandler = () => {
        eventsContext.dispatch({ type: EventActions.CHANGE_EVENT, event: events?.filter(event => event.id == selectedEventId).pop() });
        handleClose();
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isDialogOpened}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle id="alert-dialog-title">Schimbă evenimentul</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {events &&
                        <FormControl variant="standard" className={classes.formControl}>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Select a div"
                                value={selectedEventId}
                                disableUnderline={true}
                                onChange={onOptionChange}
                            >
                                {events?.map((event) => (
                                    <MenuItem key={event.id} value={event.id} className={classes.optionRoot}>
                                        <Card className={classes.cardRoot}>
                                            <CardContent>
                                                <Typography gutterBottom>
                                                    {event.eventTypeName}: {event.title}
                                                </Typography>                                               
                                                <Typography variant="body2">
                                                    {new Date(event.startDateTime).toLocaleDateString('ro-RO', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit'})}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Anulează</Button>
                    <Button onClick={changeEventHandler} autoFocus>Selectează</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}