import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";

// core components
import Admin from "./layouts/Admin.js";
import UserLogin from "./views/UserProfile/UserLogin.js";
import UserRegistration from "./views/UserProfile/UserRegistration.js";

import "./assets/css/material-dashboard-react.css?v=1.9.0";
import {UserProvider} from "./contexts/UserContext";
import {EventProvider} from "./contexts/EventContext";

const hist = createBrowserHistory();

const TRACKING_ID = "UA-129082180-6";
ReactGA.initialize(TRACKING_ID);


ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <Router history={hist}>
        <Switch>
          <PrivateRoute path="/admin">
            <EventProvider>
              <Admin />
            </EventProvider>
          </PrivateRoute>
          <Route exact path="/login" component={UserLogin} />
          <Route exact path="/register" component={UserRegistration} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Router>,
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// ReactDOM.render(
//   document.getElementById("root")
// );



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
