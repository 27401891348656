import axios from "axios";

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
};

export const get = async (url:string) => {
    return await axios(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${url}`,
        {headers}
    );
};
export const post = async (url:string, body:any) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}${url}`,
        body,
        {headers}
    );
};
export const patch = async (url:string, body:any) => {
    return await axios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}${url}`,
        body,
        {headers}
    );
};
