import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionDetails,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";
import { patch } from "../../utils/ApiUtils";

const useStyles = makeStyles(styles as any);

export const EditTable: React.FC<any> = (props) => {
    const { isDialogOpened, handleCloseDialog, table, setTable, tables, tableColors} = props;
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<any>): void => {
        setTable({
            ...table,
            [event.target.name]: event.target.value,
        });
        console.log(table);
    }

    const editTableHandler = async () => {
        const payload = JSON.stringify(table);
        const response = await patch('/api/react/guestTable/edit',payload);
        console.log(`The result of the edit confirmation ${response.data.tableUpdated}`);
        if(response.data.tableUpdated){
            const editedTable = tables.find((el: { id: number; }) => el.id == response.data.table.id);
            const data = response.data.table;
            editedTable.title = data.title;
            editedTable.description = data.description;
            editedTable.icon = data.icon;
            editedTable.color = data.color;
            editedTable.numberOfGuests = data.numberOfGuests;
            editedTable.tableNo = data.tableNo;
            handleCloseDialog();
        }
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isDialogOpened}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle id="alert-dialog-title">Editează</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Accordion className="dialog-content">
                        <AccordionDetails className={classes.details}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                                <TextField fullWidth margin="normal" id="standard-basic" name="title" label="Titlu" value={table?.title || ''} onChange={handleChange} />
                            </GridItem>
                            <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                                <TextField fullWidth multiline margin="normal" id="standard-basic" name="description" label="Descriere" value={table?.description || ''} inputProps={{ maxLength: 90 }} onChange={handleChange} />
                            </GridItem>
                            <GridItem xs={12} sm={6} className={classes.dialogGridItem}>
                                <FormControl variant="standard" fullWidth margin="normal">
                                    <InputLabel id="demo-simple-select-standard-label">Culoare</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={table?.color}
                                        onChange={handleChange}
                                        name="color"
                                        label="Culoare"
                                    >
                                    {tableColors.map((tableColor: {code: string, title: string}) => <MenuItem key={tableColor.code} value={tableColor.code}>{tableColor.title}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {/* <TextField fullWidth margin="normal" id="standard-basic" name="color" label="Culoare" value={table?.color || ''} onChange={handleChange} /> */}
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <TextField margin="normal" id="standard-basic" name="numberOfGuests" label="Nr. persoane la masă" type="number" value={table?.numberOfGuests} onChange={handleChange} />
                            </GridItem>
                        </GridContainer>
                        </AccordionDetails>
                        <Divider />
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Renunță</Button>
                    <Button onClick={editTableHandler} autoFocus>Salvează</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}