// TODO:
// 1. Todos query - sorted by created_at, updated_at or due_date?
// 2. .js to .tsx conversion
// 3. styles and page responsiveness
// 4. fix Chrome Dev Tools error regarding date type - todo-dueDate - it might be from the api call
// Failed prop type: Invalid prop `todos[0].dueDate` of type `String` supplied to `Todos`, expected instance 
// of `Date`.

// 5. Add validations for add/edit todo + add/edit rsvp/guest
// 6. Translate KeyboardDatePicker
// 7. Check editTodoHandler - is updating done immediately or is there a 1 second delay? 
// 8. Fix time from KeyboardDatePicker in db.
// 9. Populate todos based on event type on createInvite/paidMembership.


import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import dialogStyles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";
import Todos from "../../components/Tasks/Todos";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import List from '@material-ui/icons/List';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { Fab, MenuItem } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@material-ui/core";
import { Accordion, AccordionDetails } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import { patch } from "../../utils/ApiUtils";
import Todo from "../../components/Tasks/Todo.js";
import { EventsDataContext } from "../../contexts/EventContext";

const useStyles = makeStyles(styles as any);
const useDialogStyles = makeStyles(dialogStyles as any);

type Todo = {
  id?: number,
  description: string,
  notes?: string,
  dueDate: Date | null,
  category: string,
  status: string,
  eventId: number;
}

type TodoCategory = typeof TodoCategory[keyof typeof TodoCategory]
const TodoCategory = {
    Providers: {category: "providers", name: "Servicii"},
    Church: {category: "church", name: "Biserică"},
    Preparations: {category: "preparations", name: "Pregătiri"},
    Others: {category: "others", name: "Altele"},
} as const;

// enum TodoCategory {
//   Providers = "providers",
//   Church = "church",
//   Preparations = "preparations",
//   Others = "others"
// }

type TodoStatus = typeof TodoStatus[keyof typeof TodoStatus]
const TodoStatus = {
    Open: {status: "open", name: "De făcut"},
    Completed: {status: "completed", name: "Done"}
} as const;

// enum TodoStatus {
//   Open = "open",
//   Completed = "completed"
// }

export const ToDoList: React.FC<{}> = (props) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const eventsData = useContext(EventsDataContext);
  const initialTodo: Todo = {description:"", eventId:eventsData.state.currentEvent?.id, dueDate: new Date(), category:"providers", status: 'open'};
  const [todoItems, setTodoItems] = useState<Todo[]>([]);
  const [providersTodoItems, setProvidersTodoItems] = useState<Todo[]>([]);
  const [churchTodoItems, setChurchTodoItems] = useState<Todo[]>([]);
  const [preparationsTodoItems, setPreparationsTodoItems] = useState<Todo[]>([]);
  const [otherTodoItems, setOtherTodoItems] = useState<Todo[]>([]);
  const [status, setStatus] = useState<string>('ready');
  const [openAddTodoForm, setOpenAddTodoForm] = useState<boolean>(false);
  const [newTodo, setNewTodo] = useState<Todo>(initialTodo);

  // set todos based on category
  const setCategoryTodos = (todos:Todo[]) => {
    setPreparationsTodoItems(todos.filter((item) => item.category === TodoCategory.Preparations.category))
    setChurchTodoItems(todos.filter((item) => item.category === TodoCategory.Church.category))
    setProvidersTodoItems(todos.filter((item) => item.category === TodoCategory.Providers.category))
    setOtherTodoItems(todos.filter((item) => item.category === TodoCategory.Others.category))
  }

  // fetch todos from db
  useEffect(() => {
    setStatus('loading');
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/todos/filter/${eventsData.state.currentEvent?.id}`)
        .then(response => response.json())
        .then(response => {
          setTodoItems(response);
          setCategoryTodos(response);
          setStatus('ready');
        })
        .catch(error => setStatus('error'));
  }, [eventsData.state.currentEvent?.id]);

  // update todo status
  const handleTodoStatusChange = async (todoId:number, todoStatus: string) => {
    console.log(`Change status for todo with id ${todoId}`);
    const payload = {
      todoId: todoId,
      status: todoStatus
    };
    const response = await patch('/api/react/todos/updateStatus', payload);
    console.log(`The result of the todo status update ${response.data.todoStatusUpdated}`);
    if(response.data.todoStatusUpdated){
      let updatedTodo = todoItems.find((item => item.id === todoId));
      updatedTodo!.status = response.data.updatedStatus;
    }
  };

  // delete todoItem
  const deleteTodoItem: (todoId: number) => void = (todoId) => {
    console.log(`Delete invite with id ${todoId}`);
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/todos/delete/${todoId}`, 
      {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        }
      })
      .then(response => response.json())
      .then(responseJson => {
        if(responseJson.todoDeleted){
          console.log(`Todo ${todoId} deleted`);
          const updatedTodos = todoItems.filter(item => item.id !== todoId);
          setTodoItems(updatedTodos);
          setCategoryTodos(updatedTodos);
        } else {
          console.warn(`No todo deleted for id ${todoId}`);
        }
      })
      .catch(error => setStatus('error'));
  };

  // add todo item
  const saveNewTodoHandler = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/todos/add`, 
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
        body: JSON.stringify(newTodo) // body data type must match "Content-Type" header
      })
      .then(response => response.json())
      .then(responseJson => {
        const updatedTodos = [responseJson, ...todoItems];
        setTodoItems(updatedTodos);
        setCategoryTodos(updatedTodos);
        setNewTodo(initialTodo);
        setOpenAddTodoForm(false);
      })
      .catch(error => setStatus('error'));
  };

  // edit todo item
  const editTodoHandler = async (todo:Todo) => {
    const payload = JSON.stringify(todo);
    const response = await patch('/api/react/todos/edit',payload);
    console.log(`The result of the edit confirmation ${response.data.todoUpdated}`);
    if(response.data.todoUpdated){
      const editedTodo = todoItems.find(element => element.id == response.data.todo.id);
      const data = response.data.todo;
      editedTodo!.description = data!.description;
      editedTodo!.notes = data!.notes;
      editedTodo!.dueDate = data!.dueDate;
      editedTodo!.category = data!.category;
      editedTodo!.status = data!.status;
      setTodoItems(todoItems);
      setCategoryTodos(todoItems);
      handleClose();
    }
  };

  // add todo form handlers
  const handleClose = () => {
    setOpenAddTodoForm(false);
  };

  const handleNewTodoChange = (event: React.ChangeEvent<HTMLInputElement>): void  => {
    setNewTodo({
      ...newTodo,
      [event.target.name]: event.target.value,
    });
  };

  const handleDueDateChange = (date: Date | null) : void => {
    setNewTodo({
      ...newTodo,
      dueDate: date
    })
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.todosContainer}>
          <CustomTabs
            headerColor="aluminium"
            classname="todoList"
            tabs={[
              {
                tabName: "Toate",
                tabIcon: List,
                tabContent: (
                  <Todos
                    todos={todoItems}
                    status={status}
                    handleTodoStatusChange={handleTodoStatusChange}
                    deleteTodoItem={deleteTodoItem}
                    editTodoItem={editTodoHandler}
                    TodoStatus={TodoStatus}
                    TodoCategory={TodoCategory}
                  />
                ),
              },
              {
                tabName: "Pregătire",
                tabIcon: AlarmOnIcon,
                tabContent: (
                  <Todos
                    todos={preparationsTodoItems}
                    status={status}
                    handleTodoStatusChange={handleTodoStatusChange}
                    deleteTodoItem={deleteTodoItem}
                    editTodoItem={editTodoHandler}
                    TodoStatus={TodoStatus}
                    TodoCategory={TodoCategory}
                  />
                ),
              },
              {
                tabName: "Biserică",
                tabIcon: AccountBalanceIcon,
                tabContent: (
                  <Todos
                    todos={churchTodoItems}
                    status={status}
                    handleTodoStatusChange={handleTodoStatusChange}
                    deleteTodoItem={deleteTodoItem}
                    editTodoItem={editTodoHandler}
                    TodoStatus={TodoStatus}
                    TodoCategory={TodoCategory}
                  />
                ),
              },
              {
                tabName: "Servicii",
                tabIcon: CreditCardIcon,
                tabContent: (
                  <Todos
                    todos={providersTodoItems}
                    status={status}
                    handleTodoStatusChange={handleTodoStatusChange}
                    deleteTodoItem={deleteTodoItem}
                    editTodoItem={editTodoHandler}
                    TodoStatus={TodoStatus}
                    TodoCategory={TodoCategory}
                  />
                ),
              },
              {
                tabName: "Altele",
                tabIcon: Cloud,
                tabContent: (
                  <Todos
                    todos={otherTodoItems}
                    status={status}
                    handleTodoStatusChange={handleTodoStatusChange}
                    deleteTodoItem={deleteTodoItem}
                    editTodoItem={editTodoHandler}
                    TodoStatus={TodoStatus}
                    TodoCategory={TodoCategory}
                  />
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
      <Fab color="primary" aria-label="add" className={classes.addBtn} onClick={() => setOpenAddTodoForm(!openAddTodoForm)}>
        <Add />
      </Fab>
      <Dialog open={openAddTodoForm} onClose={()=>handleClose}>
        <DialogTitle>Adaugă todo</DialogTitle>
        <DialogContent className={dialogClasses.dialogContent}>
          <Accordion className="dialog-content">
            <AccordionDetails className={dialogClasses.details}>
              <GridContainer>
                <GridItem xs={12} sm={12} className={dialogClasses.dialogGridItem}>
                  <TextField fullWidth margin="normal" id="standard-basic" name="description" label="Descriere" value={newTodo.description} onChange={handleNewTodoChange} inputProps={{ maxLength: 1024 }} />
                </GridItem>
                <GridItem xs={12} sm={12} className={dialogClasses.dialogGridItem}>
                  <TextField fullWidth margin="normal" id="standard-basic" name="notes" label="Note" value={newTodo.notes} onChange={handleNewTodoChange} inputProps={{ maxLength: 1024 }} />
                </GridItem>
                <GridItem xs={12} sm={12} className={dialogClasses.dialogGridItem}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      format="dd.MM.Y"
                      name="dueDate"
                      value={newTodo.dueDate}
                      onChange={handleDueDateChange}
                      cancelLabel="Anulează"
                      label="Termen"
                      InputProps={{ readOnly: true }}
                      minDate={new Date("2021-01-01 00:00:00")}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} className={dialogClasses.dialogGridItem}>
                  <TextField
                    fullWidth
                    select
                    margin="normal"
                    label="Categorie"
                    name="category"
                    value={newTodo.category}
                    onChange={handleNewTodoChange}
                  >
                    {
                      Object.values(TodoCategory).map(item => {
                        return (
                          <MenuItem key={item.category} value={item.category}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    }
                  </TextField>
                </GridItem>
                <GridItem xs={12} sm={12} className={dialogClasses.dialogGridItem}>
                  <TextField
                    fullWidth
                    select
                    margin="normal"
                    label="Status"
                    name="status"
                    value={newTodo.status}
                    onChange={handleNewTodoChange}
                  >
                    {
                      Object.values(TodoStatus).map(item => {
                        return (
                          <MenuItem key={item.status} value={item.status}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    }
                  </TextField>
                </GridItem>
              </GridContainer>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Renunță</Button>
          <Button onClick={saveNewTodoHandler}>Adaugă</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
