import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionDetails,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Chip,
    Icon
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";

import { Guest } from "../../types/Guest";
import { Tag } from "../../types/Tag";

import OutlinedDiv from "../../components/Textfield/OutlinedDiv";
import AutocompleteField from "./AutocompleteField";


const useStyles = makeStyles(styles as any);

export const AddInvite: React.FC<any> = (props) => {
    const classes = useStyles();
    const { isDialogOpened, handleCloseDialog, eventId, TABLE_NUMBERS, dbGroupTagsList, dbIndividualTagsList, setGuests, setStatus} = props;
    const initialGuest: Guest = {name:"", eventId: eventId, numberOfPersons: 1, status:"pending", phone: '', email:'', groupTags: [], individualTags: [], acceptedTerms: 0};
    const [newGuest, setNewGuest] = useState<Guest>(initialGuest);
    const [groupTagsList, setGroupTagsList] = useState<Tag[]>([]);
    const [individualTagsList, setIndividualTagsList] = useState<Tag[]>([]);
    const [selectedGroupTags, setSelectedGroupTags] = useState<Tag[]>([]);
    const [selectedIndividualTags, setSelectedIndividualTags] = useState<Tag[]>([]);

    const handleClose = () => {
        setNewGuest(initialGuest);
        setSelectedGroupTags([]);
        setSelectedIndividualTags([]);
        setGroupTagsList(dbGroupTagsList);
        setIndividualTagsList(dbIndividualTagsList);
        handleCloseDialog();
    }


    useEffect(() => {
        setGroupTagsList(dbGroupTagsList);
        setIndividualTagsList(dbIndividualTagsList);
    }, [dbGroupTagsList, dbIndividualTagsList]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setNewGuest({
            ...newGuest,
            [event.target.name]: event.target.value,
        });
    }

    const handleGroupTagsChange = (event:React.ChangeEvent<HTMLInputElement>, values:any): void  => {
        const lastTag = values?.pop();
        setGroupTagsList(groupTagsList.filter(item => item.name !== lastTag?.name));
        setSelectedGroupTags([...selectedGroupTags, lastTag]);
    };

    const handleIndividualTagsChange = (event:React.ChangeEvent<HTMLInputElement>, values:any): void  => {
        const lastTag = values?.pop();
        setIndividualTagsList(individualTagsList.filter(item => item.name !== lastTag?.name));
        setSelectedIndividualTags([...selectedIndividualTags, lastTag]);
    };

    const handleDeleteGroupTag = (tagToDelete:Tag) => () => {
        setSelectedGroupTags((tags:Tag[]) => tags.filter((tag) => tag.id !== tagToDelete.id));
        setGroupTagsList([...groupTagsList, tagToDelete]);
    };
    
    const handleDeleteIndividualTag = (tagToDelete:Tag) => () => {
        setSelectedIndividualTags((tags:Tag[]) => tags.filter((tag) => tag.id !== tagToDelete.id));
        setIndividualTagsList([...individualTagsList, tagToDelete]);
    };

    const addNewGuestHandler = () => {
        newGuest.groupTags = selectedGroupTags;
        newGuest.individualTags = selectedIndividualTags;
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guests/add`, 
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin':'*'
                },
                body: JSON.stringify(newGuest) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(responseJson => {
                setGuests(guests => [...guests, responseJson]); 
                setNewGuest(initialGuest);
                setSelectedGroupTags([]);
                setSelectedIndividualTags([]);
                setGroupTagsList(dbGroupTagsList);
                setIndividualTagsList(dbIndividualTagsList);
                handleCloseDialog();
            })
            .catch(error => setStatus('error'));
      };

    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isDialogOpened}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle id="alert-dialog-title">Adaugă invitat</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Accordion className="dialog-content">
                        <AccordionDetails className={classes.details}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} className={classes.dialogGridItem}>
                                    <TextField fullWidth margin="normal" id="standard-basic" name="name" label="Nume" value={newGuest.name} onChange={handleChange} />
                                </GridItem>
                                <GridItem xs={6} sm={3} className={classes.dialogGridItem}>
                                    <FormControl variant="standard" fullWidth margin="normal">
                                        <InputLabel id="demo-simple-select-standard-label">Nr. persoane</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={newGuest?.numberOfPersons}
                                            onChange={handleChange}
                                            name="numberOfPersons"
                                            label="Nr. persoane"
                                        >
                                            {[...Array(10)].map((x, i) => <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6} sm={3} className={classes.dialogGridItem}>
                                    <FormControl variant="standard" fullWidth margin="normal">
                                        <InputLabel id="demo-simple-select-standard-label">Nr. masă</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={newGuest?.tableNo || ""}
                                            onChange={handleChange}
                                            name="tableNo"
                                            label="Nr. masa"
                                        >
                                        {TABLE_NUMBERS.map((tableNumber: number) => <MenuItem key={tableNumber} value={tableNumber}>{tableNumber < 10 ? `0${tableNumber}` : tableNumber}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={5} className={classes.dialogGridItem}>
                                    <TextField fullWidth margin="normal" id="standard-basic" name="phone" label="Telefon" value={newGuest.phone} onChange={handleChange} />
                                </GridItem>
                                <GridItem xs={12} sm={7} className={classes.dialogGridItem}>
                                    <TextField fullWidth margin="normal" id="standard-basic" name="email" label="Email" value={newGuest.email} onChange={handleChange} />
                                </GridItem>
                                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                                    <OutlinedDiv label="Grupuri" classes={`${classes.tableCellIcons} ${classes.tableCellIconsEdit}`}>
                                        {selectedGroupTags ? selectedGroupTags.map((tag:Tag) => {
                                            return (
                                                <Chip
                                                    key={tag.id}
                                                    icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                                                    label={tag.name}
                                                    variant="outlined"
                                                    onDelete={handleDeleteGroupTag(tag)}
                                                />
                                            );
                                        }) : ''}
                                        <AutocompleteField classes={classes} tagsList={groupTagsList} handleChange={handleGroupTagsChange}/>
                                    </OutlinedDiv>
                                </GridItem>
                                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                                    <OutlinedDiv label="Etichete" classes={`${classes.tableCellIcons} ${classes.tableCellIconsEdit}`}>
                                        {selectedIndividualTags ? selectedIndividualTags.map((tag:Tag) => {
                                            return (
                                                <Chip
                                                    key={tag.id}
                                                    icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                                                    label={tag.name}
                                                    variant="outlined"
                                                    onDelete={handleDeleteIndividualTag(tag)}
                                                />
                                            );
                                            }) : ''}
                                        <AutocompleteField classes={classes} tagsList={individualTagsList} handleChange={handleIndividualTagsChange}/>
                                    </OutlinedDiv>
                                </GridItem>
                            </GridContainer>
                        </AccordionDetails>
                        <Divider />
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Renunță</Button>
                    <Button onClick={addNewGuestHandler} autoFocus>Adaugă</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}