import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  blackColor
} from "../../material-dashboard-react.js";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& [class*='warningText']": {
      fontSize: "12px!important"
    }
  },
  editMenu: {
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    },
    "& .MuiListItemText-root span": {
      fontWeight: 600
    }
  },
  tablesContainer: {
    justifyContent: "left",
    "@media (max-width: 599px)": {
      justifyContent: "center"
    },
    '& ol li': {
      fontWeight: 600
    }
  },
  tableCard: {
    minWidth: "270px",
    flexGrow: 1,
    "@media (min-width: 960px)": {
      minWidth: "320px",
      maxWidth: "50%",
      flexBasis: "50%",
    },
    "@media (min-width: 1130px)": {
      minWidth: "270px",
      maxWidth: "33.33%",
      flexBasis: "28%"
    },
    "@media (min-width: 1370px)": {
      minWidth: "270px",
      maxWidth: "33.33%",
    },
    "@media (min-width: 1500px)": {
      maxWidth: "25%"
    },
    "@media (min-width: 1920px)": {
      minWidth: "320px",
      maxWidth: "380px"
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    fontWeight: 500,
    marginTop: "0",
    marginBottom: "0",
    "&.no-pt": {
      paddingTop: 0
    }
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: blackColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    },
    "&.mt10": {
      marginTop: "10px!important"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  card: {
    height: "100vh"
  },
  root: {
    width: '100%',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid`,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  addBtn: {
    backgroundColor: blackColor,
    position: 'fixed',
    bottom: '18px',
    right: '46px',
    '&:hover, &:visited, &:focus': {
      backgroundColor: blackColor
    },
    '&.table': {
      zIndex: 1000
    }
  },
  todosContainer: {
    "@media (max-width: 380px)": {
      padding: "0 !important"
    }
  }
};

export default dashboardStyle;
