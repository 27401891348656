import {
  defaultFont,
  primaryColor,
  dangerColor,
  grayColor,
  blackColor,
  aluminiumColor
} from "../../material-dashboard-react.js";
import tooltipStyle from "../tooltipStyle.js";
import checkboxAdnRadioStyle from "../checkboxAdnRadioStyle.js";
import {hexToRgb, whiteColor} from "../../material-dashboard-react";
const tasksStyle = {
  ...tooltipStyle,
  ...checkboxAdnRadioStyle,
  card: {
    // minHeight: "100vh",
    height: "100%",
    "@media (max-width: 675px)": {
      // width: "100%",
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
        color: grayColor[1],
        fontWeight: "400",
        lineHeight: "1"
    }
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "0",
    marginBottom: "0"
  },
  details: {
    alignItems: 'center',
    "@media (max-width: 420px)": {
      paddingBottom: "8px"
    },
    padding: 0
  },
  dialog: {
    "@media (max-width: 420px)": {
      "& .MuiDialog-paper": {
        margin: "32px 16px"
      }
    }
  },
  dialogGridItem: {
    "@media (max-width: 420px)": {
      padding: "0 8px!important"
    },
    "& label.Mui-focused": {
      color: blackColor
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: blackColor
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: blackColor
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: blackColor
      }
    }
  },
  dialogContent: {
    paddingTop: 0,
    "@media (max-width: 420px)": {
      padding: "0 18px 8px 18px"
    },
    "& div.dialog-content": {
      boxShadow: "none",
      padding: 0
    }
  },
  table: {
    marginBottom: "0",
    overflow: "visible",
    marginTop: '14px'
  },
  tableHead: {
    position: 'relative',
    marginTop: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '20px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    '& tr': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '& tr th': {
      color: blackColor,
      fontWeight: 'bold'
    },
    "@media (max-width: 500px)": {
      '& tr th': {
        paddingLeft: 0
      },
      '&.todos': {
        '& tr th': { paddingLeft: '16px' }
      }
    },
  },
  iconHeaderCell: {
    padding: "0 12px 0 0!important",
    textAlign: "right",
    lineHeight: "100%"
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid " + grayColor[5],
    '&:last-of-type': {
      borderBottom: 'none'
    },
    "&.todo": {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap'
    }
  },
  tableActions: {
    display: "flex",
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle",
    "@media (max-width: 500px)": {
      paddingTop: "2px!important",
      paddingBottom: "0!important"
    },
  },
  tableCell: {
    ...defaultFont,
    padding: "8px",
    verticalAlign: "top",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px",
    paddingTop: "11px",
    "@media (max-width: 693px)": {
      paddingTop: "10px"
    },
    "& div.MuiChip-outlined, div.MuiChip-outlined:focus": {
      border: "none",
      backgroundColor: blackColor,
      color: whiteColor,
      fontSize: "12px",
      fontWeight: 600
    }
  },
  defaultText: {
    textAlign: "center",
    padding: "140px 0 !important",
    borderBottom: "none"
  },
  todosTable: {
    "& tr td": { padding: 0 },
    "& .todo-row:last-of-type td": { borderBottom: "none" }
  },
  todoTable: {
    "& td": {
      padding: 0,
      borderBottom: "none",
      textAlign: "left"
    },
    "& td.w0": { width: 0 },
    "& .description": {
      fontWeight: 600
    },
    "& .notes-row": {
      paddingTop: 0,
      "& p": { 
        margin: 0,
        fontSize: "12px",
        fontWeight: 600,
        color: "grey",
        marginBottom: "10px"
      }
    }
  },
  tableCellIcons: {
    ...defaultFont,
    verticalAlign: "top",
    border: "none",
    '& .MuiChip-root': {
      margin: '3px'
    },
    padding: "8px",
    "& div.MuiChip-outlined": {
      border: "none",
      paddingLeft: "4px",
      backgroundColor: aluminiumColor,
      color: whiteColor,
      fontSize: "12px",
      fontWeight: 600
    }
  },
  tableCellIconsEdit: {
    ...defaultFont,
    verticalAlign: "top",
    border: "none",
    '& .MuiChip-root': {
      margin: '3px'
    },
    '& .MuiChip-label': {
      "@media (max-width: 693px)": {
        padding: "0 8px"
      }
    },
    '& .MuiChip-deleteIcon': {
      color: whiteColor
    }
  },
  cellTitle: {
    fontWeight: 'bold'
  },
  cellText: {
    fontSize: '12px',
    fontWeight: 600,
    color: grayColor[7]
  },
  coloredOutline: {
    border: "2px solid #00acc1",
    "&:hover": {
      color: blackColor + "!important",
      "& svg": {
        color: blackColor + '!important'
      }
    }
  },
  pt14: {
    paddingTop: "14px"
  },
  pl28: {
    paddingLeft: "28px!important"
  },
  autocompleteOption: {
    '&[data-focus="true"]': {
      backgroundColor: blackColor,
      color: '#fff'
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#fff'
    },
  },
  tableCellRTL: {
    textAlign: "right"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor[0],
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none"
  },
  addBtn: {
    backgroundColor: blackColor,
    position: 'fixed',
    bottom: '18px',
    right: '46px',
    '&:hover, &:visited, &:focus': {
      backgroundColor: blackColor
    }
  },
  editMenu: {
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    },
    "& .MuiListItemText-root span": {
      fontWeight: 600
    }
  },
// change event modal styles
  formControl: {
    width: "100%",
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff"
    },
    "& .MuiSelect-selectMenu": {
      whiteSpace: "normal"
    }
  },
  optionRoot: {
    "&.MuiListItem-root.MuiListItem-button:hover": {
      background: "#fff"
    },
    "&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover": {
      background: whiteColor,
      "& div": {
        background: blackColor,
        color: whiteColor
      }
    },
    "&.MuiMenuItem-root": {
      whiteSpace: "normal"
    }
  },
  cardRoot: {
    width: "100%"
  }
  // change event modal styles end
};
export default tasksStyle;
