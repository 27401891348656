/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import EventIcon from '@material-ui/icons/Event';
import BookmarksRoundedIcon from '@material-ui/icons/BookmarksRounded';
import RestaurantMenuRoundedIcon from '@material-ui/icons/RestaurantMenuRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
// import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import { Dashboard as TablesSetup } from "./views/TablesSetup/TablesSetup";
import { Invites } from "./views/Invites/Invites";
import { EventDay } from "./views/EventDay/EventDay";
import { ToDoList } from "./views/ToDoList/ToDoList";
import { Wishlist } from "./views/Wishlist/Wishlist";
import { Home } from "./views/Home/Home";
// import UserProfile from "views/UserProfile/UserProfile.js";
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Acasă",
    icon: HomeIcon,
    component: Home,
    layout: "/admin"
  },
  /*{
    path: "/dashboard",
    name: "Evenimente",
    icon: Dashboard,
    component: TablesSetup,
    layout: "/admin"
  },*/
  {
    path: "/invitati",
    name: "Invitați",
    icon: PeopleAltRoundedIcon,
    component: Invites,
    layout: "/admin"
  },
  /*{
    path: "/ziua-evenimentului",
    name: "Ziua evenimentului",
    icon: EventIcon,
    component: EventDay,
    layout: "/admin"
  },*/
  {
    path: "/planificare-eveniment",
    name: "Planificare eveniment",
    icon: DateRangeIcon,
    component: ToDoList,
    layout: "/admin"
  },
  {
    path: "/aranjarea-la-mese",
    name: "Aranjarea la mese",
    icon: RestaurantMenuRoundedIcon,
    component: TablesSetup,
    layout: "/admin"
  },
  /*{
    path: "/wishlist",
    name: "Wishlist",
    icon: BookmarksRoundedIcon,
    component: Wishlist,
    layout: "/admin"
  }*/
];

export default dashboardRoutes;
