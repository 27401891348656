import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    // margin: "0 -15px !important",
    // width: "100%",
    margin: "0"
  }
};

const useStyles = makeStyles(styles);

export default function GridContainer(props) {
  const classes = useStyles();
  const { children, classname, ...rest } = props;
  return (
    <Grid container {...rest} className={`${classes.grid} ${classname}`} >
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node,
  classname: PropTypes.string
};
