import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardFooter from "../../components/Card/CardFooter.js";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import Warning from "../../components/Typography/Warning.js";
import { Fab, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@material-ui/core";
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { Add } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

import { Guest } from "../../types/Guest";
import { Table } from "../../types/Table";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {EventsDataContext} from "../../contexts/EventContext";
import { EditTable } from "./EditTable";
import { AddTable } from "./AddTable";

const useStyles = makeStyles(styles as any);
const DEFAULT_NO_OF_GUESTS: number = 10;
const DEFAULT_TABLE_COLOR: string = 'black';
const TABLE_NUMBERS: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const TABLE_COLORS: Array<{ code: string, title: string }> = [{code: 'info', title: 'Albastru'}, {code: 'indigo', title: 'Indigo'}, {code: 'primary', title: 'Mov'}, {code: 'black', title: 'Negru'}, {code: 'success', title: 'Verde'}];

export const Dashboard: React.FC<{}> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [tables, setTables] = useState<Table[]>([]); // create
  const [status, setStatus] = useState<string>('ready');
  const eventsData = useContext(EventsDataContext);
  const [selectedTable, setSelectedTable] = useState<Table>();
  const [selectedTableId, setSelectedTableId] = useState<number>();
  const [isEditTableModalOpen, setIsEditTableModalOpen] = useState<boolean>(false);
  const [isAddTableModalOpen, setIsAddTableModalOpen] = useState<boolean>(false);

  const arrangePeopleOnTables = (tables:Table[], guests:Guest[]) => {
    const tablesByIdx:Map<number, Table> = new Map<number, Table>();

    if(tables.length) {
      tables.map(table => tablesByIdx.set(table.tableNo, {...table, guests:[]}));
    }

    guests.forEach(guest => {
      if(!guest.tableNo) {
        return;
      }

      if(!tablesByIdx.has(guest.tableNo as number)){
        createNewTable(guest);
        tablesByIdx.set(guest.tableNo as number, {title: '', description: '', color: DEFAULT_TABLE_COLOR, tableNo: guest.tableNo as number, eventId: guest.eventId, numberOfGuests: DEFAULT_NO_OF_GUESTS, guests: [guest]});
      } else {
        const guestData = tablesByIdx.get(guest.tableNo as number);
        if(guestData !== undefined) {
          guestData.guests.push(guest);
        }
      }
    });
    setTables(Array.from(tablesByIdx.values()).sort((a, b) => a.tableNo - b.tableNo));
  };

  const createNewTable = async (guest:Guest) => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guestTable/add`, 
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
        body: JSON.stringify({tableNo: guest.tableNo, eventId: guest.eventId, numberOfGuests: DEFAULT_NO_OF_GUESTS, color: DEFAULT_TABLE_COLOR})
      })
      .catch(error => console.log('error'));
  };

  console.log("TablesSetup -> Current Event Title " + eventsData.state.currentEvent?.title);

  //  TODO: save and load the tables
  // useEffect(() => {
  //   setStatus('loading');
  //   fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/tables/filter/${eventsData.state.currentEvent?.id}`)
  //       .then(response => response.json())
  //       .then(response => {
  //         setStatus('ready');
  //         arrangePeopleOnTables(response);
  //       })
  //       .catch(error => setStatus('error'));
  // }, [eventsData.state.currentEvent?.id]);

  //   // TODO: save and load the tables
  // useEffect(() => {
  //   setStatus('loading');
  //   fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guests/filter/${eventsData.state.currentEvent?.id}`)
  //       .then(response => response.json())
  //       .then(response => {
  //         setStatus('ready');
  //         arrangePeopleOnTables(response);
  //       })
  //       .catch(error => setStatus('error'));
  // }, [eventsData.state.currentEvent?.id]);

  const getTables = async (currentEventId:number) => {
    setStatus('loading');
    const tablesResponse = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/tables/filter/${currentEventId}`);
    const guestsResponse = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guests/filter/${currentEventId}`)
    const tablesResult:Table[] = await tablesResponse.json();
    const guestsResult:Guest[] = await guestsResponse.json();
    setStatus('ready');
    arrangePeopleOnTables(tablesResult, guestsResult);
  };

  useEffect(() => {
    getTables(eventsData.state.currentEvent?.id as number).catch(console.error);
  }, [eventsData.state.currentEvent?.id]);

  const handleClickForTable = (table: Table) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      console.log(table);
      setSelectedTable(table);
      setAnchorEl(event.currentTarget);
      setSelectedTableId(table.id);
    };
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditTableModal = () => {
    setIsEditTableModalOpen(!isEditTableModalOpen);
    handleClose();
  };

  const deleteTable: () => void = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guestTable/delete/${selectedTableId}`, 
      {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        }
      })
      .then(response => response.json())
      .then(responseJson => {
        if(responseJson.tableDeleted){
          console.log(`Guest ${selectedTableId} deleted`);
          const updatedTables = tables.filter(table => table.id !== selectedTableId);
          setTables(updatedTables);
        } else {
          console.warn(`No guest deleted for id ${selectedTableId}`);
        }
      })
      .catch(error => setStatus('error'));
  };

  const getTotalNumberOfGuests = (guestsArray: Guest[]) => {
    return guestsArray.reduce((prev: number, current: { numberOfPersons: string | number; }) => prev + +current.numberOfPersons, 0);
  }

  return (
    <div>
      <GridContainer classname={classes.tablesContainer}>
        { tables?.map( (table:Table, index:number) =>
            (
                <GridItem xs={12} sm={6} md={4} key={`table-${index}`} className={classes.tableCard}>
                  <Card>
                    <CardHeader color={table.color} stats icon>
                      <CardIcon color={table.color} className='table-icon'>
                        <LocalDiningIcon />
                      </CardIcon>
                      <h3 className={`${classes.cardTitle} mt10`}>
                        {(table.title ? table.title : 'Masa') + (table.tableNo < 10 ? ` 0${table.tableNo}` : ` ${table.tableNo}`)}
                      </h3>
                      <p className={classes.cardCategory}>{table.description ? table.description : ' ' }</p>
                    </CardHeader>
                    { table.guests &&
                      <ol>
                        { table.guests.map( (guest:Guest) =>
                            ( <li key={`guest-${guest.id}`}>{guest.name} { guest.numberOfPersons > 1 ? `(${guest.numberOfPersons} pers.)` : '' }</li> )
                        )}
                      </ol>
                    }
                    <CardFooter stats>
                      <div className={`${classes.stats} small-text`}>
                        {table.guests ? (table.numberOfGuests - getTotalNumberOfGuests(table.guests) === 1 ? '1 loc liber'  :  table.numberOfGuests - getTotalNumberOfGuests(table.guests) < 0 ? <Warning>Limita de persoane a fost depășită.</Warning> : table.numberOfGuests - getTotalNumberOfGuests(table.guests) + ' locuri libere') : table.numberOfGuests + ' locuri libere'}
                      </div>
                      <Tooltip
                          id="tooltip-top"
                          title="Editează"
                          placement="top"
                          classes={{tooltip: classes.tooltip}}
                      >
                        <IconButton aria-controls="simple-menu" aria-haspopup="true"
                          onClick={handleClickForTable(table)}>
                          <MoreVertIcon/>
                        </IconButton>
                      </Tooltip>
                    </CardFooter>
                  </Card>
                </GridItem>
            )
        )}
        <Menu
          id="simple-menu"
          className={classes.editMenu}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEditTableModal}>
            <ListItemIcon>
              <EditIcon style={{ color: "rgba(0, 0, 0, 0.8)" }}/>
            </ListItemIcon>
            <ListItemText primary="Editează" />
          </MenuItem>
          <DeleteButton buttonText="Șterge" 
            confirmTitle="Șterge masa"
            confirmText={`Ștergi masa numărul ${selectedTable?.tableNo}?`}
            confirmedDeleteHandler={deleteTable}
            modalOpenHandler={handleClose}
          />
        </Menu>
      </GridContainer>
      <Fab color="primary" aria-label="add" className={`${classes.addBtn} table`} onClick={() => setIsAddTableModalOpen(!isAddTableModalOpen)}>
        <Add />
      </Fab>
      <EditTable
        isDialogOpened={isEditTableModalOpen}
        handleCloseDialog={() => setIsEditTableModalOpen(false)}
        table={selectedTable}
        setTable={setSelectedTable}
        tables={tables}
        tableColors={TABLE_COLORS}
      />
      <AddTable
        isDialogOpened={isAddTableModalOpen}
        handleCloseDialog={() => setIsAddTableModalOpen(false)}
        setTable={setSelectedTable}
        tables={tables}
        setTables={setTables}
        eventId={eventsData.state.currentEvent?.id}
        tableNumbers={TABLE_NUMBERS}
        tableColors={TABLE_COLORS}
      />
    </div>
  );
}