import React, {useContext} from "react";
import {
    Route,
    Redirect,
} from "react-router-dom";
import {UserContext} from "../contexts/UserContext";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ children, ...rest }) => {
    const userContext = useContext(UserContext);

    if(userContext.state.user){
        console.log(`for ${rest.path} access granted for user with id ${userContext.state.user.id}`);
    } else {
        console.log(`for ${rest.path} access blocked and authorization required`);
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                userContext.state.user ? (
                    children
                ) : (
                    userContext.state.userLoaded ? (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    ) : (
                        <span> Loading... </span>
                    )
                )
            }
        />
    );
};
