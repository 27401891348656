import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from "@material-ui/core/MenuItem";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

interface DeleteButtonProps {
  buttonText: string,
  confirmTitle: string,
  confirmText: string,
  confirmedDeleteHandler: () => void,
  modalOpenHandler: () => void
};

export default function DeleteButton(props: DeleteButtonProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    props.modalOpenHandler()
  };

  const handleClose = (commit: boolean) => {
    setOpen(false);
    if(commit){
      props.confirmedDeleteHandler();
    }
  };

  return (
    <div>
    <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
        <DeleteForeverIcon style={{ color: "rgba(0, 0, 0, 0.8)" }}/>
        </ListItemIcon>
        <ListItemText primary={props.buttonText} />
    </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.confirmTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {props.confirmText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Anulează
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Șterge
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}