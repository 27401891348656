import React, { useMemo, useReducer, useEffect} from "react";
import ReactGA from 'react-ga';
import {get} from "../utils/OctoberApiUtils";


interface User {
    id: number,
    name: string
}
interface UserState {
    user: User | null,
    userLoaded: boolean
}
interface UserAction {
    type: string,
    user: User | null,
    userLoaded: boolean
};

type UserContextState = {
    dispatch: (ua:UserAction) => void,
    state: UserState
};

const initialUserState:UserContextState = {
    dispatch: (ua:UserAction) => true,
    state: {
        user: null,
        userLoaded: false
    }
};

export const UserContext = React.createContext(initialUserState);

export const UserActions = {
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_INIT: 'USER_INIT',
};

const userReducer: (state:UserState, action:UserAction) => UserState = (state:UserState, action:UserAction) => {
    switch (action.type) {
      case UserActions.USER_INIT:
          return { ...state, user: action.user, userLoaded: true};
      case UserActions.USER_LOGIN:
          return { ...state, user: action.user};
      case UserActions.USER_LOGOUT:
        return { ...state, user: null};
      default:
        return state;
    }
  };

export const UserProvider: React.FC<{}> =  (props:any) => {
    const [state, dispatch] = useReducer(userReducer, initialUserState.state);
    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    useEffect(() => {
        async function execute(){
            if(process.env.REACT_APP_MOCKED_AUTH === 'true') {
                dispatch({ type: UserActions.USER_INIT, user: {id: 1, name: 'Local Development'}, userLoaded: true});
            } else {
                const result = await get('/api/react/auth/user');
                dispatch({ type: UserActions.USER_INIT, user: result.data, userLoaded: true});
            }
        }
        execute();
    }, []);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    return (
        <UserContext.Provider value={contextValue}>
            {props.children}
        </UserContext.Provider>
    )
};