import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";
import Todo from './Todo';

const useStyles = makeStyles(styles);

export default function Todos(props) {
  const classes = useStyles();
  const { todos, status, handleTodoStatusChange, deleteTodoItem, editTodoItem, TodoCategory, TodoStatus } = props;

  const defaultText = (
    <TableRow>
      <TableCell colSpan={5} className={classes.defaultText}>
        <Typography variant='body2'>Ai 0 activități.</Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <Table className={classes.todosTable}>
      <TableHead className={`${classes.tableHead} todos`}>
        <TableRow>
          <TableCell>Todo</TableCell>
          <TableCell width={47}>Acțiuni</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { status === 'ready' &&
          (todos.length ? todos.map(todo => (
            <Todo
              key={todo.id}
              handleTodoStatusChange={handleTodoStatusChange}
              todo={todo} deleteTodoItem={deleteTodoItem}
              editTodoItem={editTodoItem}
              TodoCategory={TodoCategory}
              TodoStatus={TodoStatus}
            />
          )) : defaultText)
        }
      </TableBody>
    </Table>
  );
}

Todos.propTypes = {
  todos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      description: PropTypes.string,
      notes: PropTypes.string,
      dueDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
      ]),
      category: PropTypes.string,
      status: PropTypes.string,
      eventId: PropTypes.number
    })
  ),
  status: PropTypes.string,
  handleTodoStatusChange: PropTypes.func,
  deleteTodoItem: PropTypes.func,
  editTodoItem: PropTypes.func,
  TodoStatus: PropTypes.object,
  TodoCategory: PropTypes.object
};