import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from "../../assets/jss/material-dashboard-react/views/homeStyle.js";
import {  Button, Tooltip } from '@material-ui/core';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FindInPageIcon from '@material-ui/icons/FindInPage';

// types
import { HomepageCardDetails } from '../../types/HomepageCardDetails.js';

const useStyles = makeStyles(styles as any);
// https://learus.github.io/react-material-ui-carousel/
export const HomepageCard: React.FC<{ cardDetails: HomepageCardDetails }> = ({ cardDetails }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
        <CardHeader
            avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                {cardDetails?.header.avatar}
                </Avatar>
            }
            title={cardDetails?.header.title}
            subheader={cardDetails?.header.subheader}
        />
        <CardMedia
            className={classes.media}
            image={cardDetails?.media.image}
            title={cardDetails?.media.title}
        />
        <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
            {cardDetails?.content}
        </Typography>
        </CardContent>
        <CardActions disableSpacing>
            <Button size="small" color="primary" className={classes.cardCta} href={cardDetails?.cardActionsUrl} target="_blank">
                Detalii
            </Button>
        {/* <Tooltip title="Cauta fotografi">
            <IconButton aria-label="Cauta fotografi">
            <FindInPageIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Adauga reminder">
            <IconButton aria-label="Adauga item de facut">
            <LibraryAddCheckIcon />
            </IconButton>
        </Tooltip>

        <Tooltip title="Adauga sendita foto in calendar">
            <IconButton aria-label="Adauga sendita foto in calendar">
            <EventAvailableIcon />
            </IconButton>
        </Tooltip>

        <IconButton
            aria-label="show more"
        >
            <MoreVertIcon />
        </IconButton> */}
        </CardActions>
    </Card>
  );
}
