import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";

import {post} from "../../utils/OctoberApiUtils";
import checkboxAdnRadioStyle from "../../assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import '../../assets/css/login-register-styles.css';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  error: {
    marginTop: '20px',
    color: '#d32f2f',
    backgroundColor: '#f2c2c2',
    padding: '12px',
    borderRadius: '4px',
    '& h4': {
      margin: 0,
      fontSize: '14px',
      fontWeight: 'bold'
    }
  },
  labelStyles: {
    '& label span.MuiTypography-root': {
      fontSize: '14px'
    },
    '& label:first-of-type': {
      marginTop: '14px',
      marginBottom: '14px'
    }
  },
  fullwidth: {
    width: '100%'
  },
  footerParagraph: {
    display: 'flex',
    justifyContent: 'right',
    margin: '5px 20px 10px 0',
    padding: 0,
    color: 'rgba(0, 0, 0, 0.47)',
    fontSize: '12px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  mt10: {
    marginTop: '10px'
  }
};

const useStyles = makeStyles(styles);
const useCheckboxStyles = makeStyles(checkboxAdnRadioStyle);

export default function UserRegistration() {
  const classes = useStyles();
  const checkboxClasses = useCheckboxStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [consentTerms, setConsentTerms] = useState(false);
  const [consentPromo, setConsentPromo] = useState(false);
  const [registrationError, setRegistrationError] = useState('');

  const handleNameChange = e => {
    setRegistrationError("");
    setName(e.target.value);
  }

  const handleEmailChange = e => {
    setRegistrationError("");
    setEmail(e.target.value);
  }

  const handlePasswordChange = e => {
    setRegistrationError("");
    setPassword(e.target.value);
  }

  const handleConfirmPasswordChange = e => {
    setRegistrationError("");
    setConfirmPassword(e.target.value);
  }

  const handleConsentTerms = () => {
    setRegistrationError("");
    setConsentTerms(!consentTerms);
  };

  const handleConsentPromo = () => {
    setRegistrationError("");
    setConsentPromo(!consentPromo);
  };

  const register = async () => {
    // try {
      const response = await post('/api/react/auth/register', {email, password, name, consentTerms, consentPromo});
      if(response.data.emailIsTaken===1){
        setRegistrationError("Adresa de e-mail introdusă este deja folosită. Te rugăm să te loghezi sau să introduci o altă adresă de e-mail.");
      }
      if(response.data.userId>0){
        console.log('user registration success')
      }
    // }
  };

  const validateAndRegister = () => {
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if(name.length == 0) {
      setRegistrationError("Numele este obligatoriu.");
    } else if (!isEmail.test(email) || email.length == 0) {
      setRegistrationError("Introduceți o adresă de email validă.");
    } else if (password.length == 0 || password.length < 8) {
      setRegistrationError("Parola este obligatorie și trebuie să conțină minim 8 caractere.");
    } else if(confirmPassword.length == 0) {
      setRegistrationError("Confirmarea parolei este obligatorie.");
    } else if(password != confirmPassword) {
      setRegistrationError("Parola și Confirmarea parolei nu sunt identice.");
    } else if(!consentTerms) {
      setRegistrationError("Te rugăm să accepți termenii de utilizare.");
    } else {
      setRegistrationError("");
      register();
    }
  }

  return (
    <div className={`${classes.mt10} formContainer`}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Înregistrează-te</h4>
              <p className={classes.cardCategoryWhite}>Creează-ți cont pe My Belle Event</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Nume"
                    id="name"
                    value={name}
                    name="userRegistrationName"
                    onChange={handleNameChange}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Adresa de email"
                    id="email-address"
                    value={email}
                    name="userRegistrationEmail"
                    onChange={handleEmailChange}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Parola"
                    id="password"
                    type="password"
                    name="userRegistrationPassword"
                    onChange={handlePasswordChange}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Confirma parola"
                    id="confirmPassword"
                    type="password"
                    name="userRegistrationPasswordConfirm"
                    onChange={handleConfirmPasswordChange}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <FormGroup className={classes.labelStyles}>
                    <FormControlLabel
                      control={<Checkbox
                        checked={consentTerms}
                        onClick={handleConsentTerms}
                        checkedIcon={<Check className={checkboxClasses.checkedIcon} />}
                        icon={<Check className={checkboxClasses.uncheckedIcon} />}
                        classes={{
                          checked: checkboxClasses.checked,
                          root: checkboxClasses.root
                        }}
                      />}
                      label={<span>Am citit și sunt de acord cu <a href='/termeni-si-conditii' target='_blank'> Termeni și condiții.</a></span>}
                    />
                    <FormControlLabel
                      control={<Checkbox
                        checked={consentPromo}
                        onClick={handleConsentPromo}
                        checkedIcon={<Check className={checkboxClasses.checkedIcon} />}
                        icon={<Check className={checkboxClasses.uncheckedIcon} />}
                        classes={{
                          checked: checkboxClasses.checked,
                          root: checkboxClasses.root
                        }}
                      />}
                      label="Sunt de acord cu primirea de materiale de marketing."
                    />
                  </FormGroup>
                </GridItem>
                {registrationError && 
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.error}>
                      <h4>{registrationError}</h4>
                    </div>
                  </GridItem>
                }
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button className={classes.fullwidth} color="primary" onClick={validateAndRegister}>Inregistreaza-te</Button>
            </CardFooter>
            <p className={classes.footerParagraph}><a href="/">Intră în contul tau My-Belle-Event</a></p>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
