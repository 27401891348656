import React, {useContext,useState, useEffect} from 'react';
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Icon, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import clsx from 'clsx';
import styles from "../../assets/jss/material-dashboard-react/views/homeStyle.js";
import { HomepageCard } from '../../components/HomepageCard/HomepageCard';
import Card from "../../components/Card/Card.js";
import CardBody from '../../components/Card/CardBody.js';
import Button from '../../components/CustomButtons/Button.js';

// contexts
import { EventsDataContext } from "../../contexts/EventContext";
import { EventStats } from "../../types/EventStats";

// images
import MyBelleEventImg from '../../assets/img/homepage/belle-event.jpg';
import LaBelleImageImg from '../../assets/img/homepage/la-belle-image.jpg';
import MyInviteImg from '../../assets/img/homepage/my-invite.jpg';

const useStyles = makeStyles(styles as any);
// https://learus.github.io/react-material-ui-carousel/
export const Home: React.FC<{}> = (props) => {
  const classes = useStyles();
  const [status, setStatus] = useState<string>('ready');
  const eventsData = useContext(EventsDataContext);
  const [eventStats, setEventStats] = useState<EventStats>({eventId: 0, confirmedRsvpCount: 0, guestTablesCount: 0, rejectedRsvpCount: 0, remainingTodosCount: 0, rsvpCount: 0, seatedGuestsCount: 0, totalGuestsCount: 0});
  const cardDetailsOne = {
    header: {avatar:'mbe', title:'Cum funcționează My Belle Event?', subheader:'Sugestii'}, 
    media: {image: MyBelleEventImg, title:'Oameni ciocnind pahare cu șampanie la un eveniment.'}, 
    content:`Pe My Belle Event găsești toate instrumentele de care ai nevoie pentru organizarea evenimentului tău.`, 
    cardActionsUrl: 'https://belle-event.ro?utm_source=MyBelleEventHomepage'
  };
  const cardDetailsTwo = {
    header: {avatar:'mi', title:'Cum funcționează My Invite?', subheader:'Sugestii'}, 
    media: {image: MyInviteImg, title:'Invitație digitală creată de my-invite.ro.'}, 
    content:'Creezi gratuit invitații online pentru evenimentul tău și în doar 15 minute vei putea da share persoanelor invitate.', 
    cardActionsUrl: 'https://my-invite.ro?utm_source=MyBelleEventHomepage'
  };
  const cardDetailsThree = {
    header: {avatar:'lbi', title:'La Belle Image', subheader:'Sugestii'}, 
    media: {image: LaBelleImageImg, title:'Un fotograf care ține un aparat foto Canon.'}, 
    content:'La Belle Image oferă servicii foto profesionale pentru evenimente: nuntă, botez, cununie civilă, petrecere majorat, etc.', 
    cardActionsUrl: 'https://la-belle-image.ro?utm_source=MyBelleEventHomepage'
  };

  useEffect(() => {
    setStatus('loading');
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/eventStats/filter/${eventsData.state.currentEvent?.id}`)
        .then(response => response.json())
        .then(response => {
          setStatus('ready');
          setEventStats(response);
        })
        .catch(error => setStatus('error'));
  }, [eventsData.state.currentEvent?.id]);

  return (
    <div>
      <Card>
        <CardBody className={classes.cardContainer}>
          {eventsData.state.currentEvent ? 
            <GridContainer classname='home'>
              <GridItem className='noPadding img'>
                <img className='stats-img' src={require(`../../assets/img/event-type-thumbnails/${eventsData.state.currentEvent?.eventTypeCode}.jpg`)} alt="event type image" />
                <Typography variant='body2' align='center'>{eventsData.state.currentEvent?.title}</Typography>
              </GridItem>
              <GridItem className='home-stats'>
                <List>
                  <ListItem component="a" href="/admin/invitati">
                    <ListItemAvatar>
                      <Avatar>
                        <Icon>recommend</Icon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={`Confirmări: ${eventStats?.rsvpCount}`}
                      secondary={
                        <React.Fragment>
                            {eventStats?.confirmedRsvpCount === 1 ? '1 confirmare' : `${eventStats?.confirmedRsvpCount} confirmări`} de participare <br />
                            {eventStats?.totalGuestsCount == 1 ? '1 participant' : `${eventStats?.totalGuestsCount} participanți`}
                        </React.Fragment>
                      } />
                  </ListItem>
                  <ListItem component="a" href="/admin/aranjarea-la-mese">
                    <ListItemAvatar>
                      <Avatar>
                        <Icon>table_bar</Icon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Mese: ${eventStats?.guestTablesCount}`}
                      secondary={
                        <React.Fragment>
                          {eventStats?.seatedGuestsCount}/{eventStats?.totalGuestsCount} invitați așezați la mese
                        </React.Fragment>
                    } />
                  </ListItem>
                  <ListItem component="a" href="/admin/planificare-eveniment">
                    <ListItemAvatar>
                      <Avatar>
                        <Icon>assignment</Icon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Pregătiri de făcut: ${eventStats?.remainingTodosCount}`} />
                  </ListItem>
                </List>    
              </GridItem>
              <GridItem className='cta'>
                <Link className='invite-btn' href={`https://my-invite.ro/invitatie/${eventsData.state.currentEvent?.inviteTemplateId}/${eventsData.state.currentEvent?.id}?utm_source=MyBelleEvent`} target="_blank">
                  <Button color='black'>Vezi invitația</Button>
                </Link>
                <Link className='invite-btn edit' href={`https://my-invite.ro/dashboard-editeaza-invitatie/${eventsData.state.currentEvent?.id}?utm_source=MyBelleEvent`} target="_blank">
                  <Button color='black'>Editează invitația</Button>
                </Link>
              </GridItem>
            </GridContainer>
            : <p>Nu ai niciun eveniment creat. Creează un eveniment <a href="https://my-invite.ro?utm_source=MyBelleEvent"><b>aici</b></a>.</p>
          }
          
          <div className={classes['gray-bg']}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <HomepageCard cardDetails={cardDetailsOne} />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <HomepageCard cardDetails={cardDetailsTwo} />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <HomepageCard cardDetails={cardDetailsThree} />
              </GridItem>
            </GridContainer>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
