import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

export default function AutocompleteField(props) {
    const { classes, tagsList, handleChange } = props;
    return (
      <React.Fragment>
        <Autocomplete
          autoHighlight={true}
          disableClearable
          multiple
          classes={{option: classes.autocompleteOption}}
          options={tagsList}
          filterOptions={createFilterOptions({ limit: 5 })}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          renderTags={() => null}
          onChange={handleChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Add"
              margin="normal"
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  getContentAnchorEl: null
                }
              }}
            />
            )}
        />
      </React.Fragment>
    );
}