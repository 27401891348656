/*eslint-disable*/
import React, {useContext} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {NavLink, useLocation} from "react-router-dom";
// @material-ui/core components
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import useMediaQuery from "@material-ui/core/useMediaQuery";
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { EventsDataContext } from "../../contexts/EventContext";
import { ChangeEventModal } from "../../views/Invites/ChangeEventModal"

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const location = useLocation();
    const eventsData = useContext(EventsDataContext);
    const [isEventModalOpen, setIsEventModalOpen] = React.useState(false);

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return location.pathname.indexOf(routeName) > -1 ? true : false;
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEventModal = () => {
        setIsEventModalOpen(!isEventModalOpen);
    };

    const {color, logo, image, logoText, routes} = props;
    var changeEventNavLink = (
        eventsData.state.events?.length > 1 &&
            <a className={classes.item}>
                <ListItem button className={`${classes.itemLink} listItemClasses`} onClick={handleEventModal}>
                    <ArrowDropDownCircleIcon className={`${classes.itemIcon} whiteFontClasses`} />
                    <ListItemText primary={eventsData.state.currentEvent.title} secondary="Schimbă evenimentul" classes={{ primary: `${classes.itemText} whiteFontClasses primary`, secondary: `${classes.itemText} secondary` }} />
                </ListItem>
            </a>
    );

    var links = (
        <List className={classes.list}>
            {changeEventNavLink}
            {routes.map((prop, key) => {
                var activePro = " ";
                var listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                });
                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                });
                return (
                    <NavLink
                        to={eventsData.state.currentEvent ? prop.layout + prop.path : prop.layout + "/dashboard"}
                        className={activePro + classes.item + (!eventsData.state.currentEvent ? " disabled" : "")}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses} onClick={mobileScreen ? props.handleDrawerToggle : undefined}>
                            <prop.icon
                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                    [classes.itemIconRTL]: props.rtlActive
                                })}
                            />
                            <ListItemText
                                primary={props.rtlActive ? prop.rtlName : prop.name}
                                className={classNames(classes.itemText, eventsData.state.currentEvent || prop.path === "/dashboard" ? whiteFontClasses : "disabled", {
                                    [classes.itemTextRTL]: props.rtlActive
                                })}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
            <NavLink
                to={eventsData.state.currentEvent ? {pathname: `https://my-invite.ro/dashboard-editeaza-invitatie/${eventsData.state.currentEvent?.id}?utm_source=MyBelleEvent`} : {pathname: "https://my-invite.ro?utm_source=MyBelleEvent"}}
                target="_blank"
                rel="noopener"
                className={classes.item}
            >
                <ListItem button className={`${classes.itemLink} listItemClasses`}>
                    <MenuBookIcon className={`${classes.itemIcon} whiteFontClasses`} />
                    <ListItemText className={`${classes.itemText} whiteFontClasses`} disableTypography={true}>Editează invitație</ListItemText>
                </ListItem>
            </NavLink>
        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <div className={classes.logoLink}>
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img}/>
                </div>
                {logoText}
            </div>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={props.rtlActive ? "left" : "right"}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        {links}
                        <AdminNavbarLinks/>
                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor={props.rtlActive ? "right" : "left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <ChangeEventModal
                isDialogOpened={isEventModalOpen}
                handleCloseDialog={() => setIsEventModalOpen(false)}
            />
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool
};
