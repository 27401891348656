import React, {useState, useEffect, useMemo, useReducer, useContext} from "react";
import { Event } from "../types/Event";
import {get} from "../utils/OctoberApiUtils";
import {UserActions} from "./UserContext";
import { UserContext } from "./UserContext";

interface EventState {
  currentEvent: Event | null,
  events: Event[] | null,
  loaded: boolean
}
interface EventAction {
  type: EventActions,
  event?: Event | null,
  events?: Event[] | null,
  loaded?: boolean
};

interface EventContextState {
  dispatch: (ua:EventAction) => void,
  state: EventState
};

const initialEventState = {
  dispatch: (action: EventAction) => {},
  state: {
    currentEvent: null,
    events: null,
    loaded: false
  }
};

export enum EventActions {
  CHANGE_EVENT = 'CHANGE_EVENT',
  EVENTS_INIT = 'EVENTS_INIT',
}

const eventReducer: (state:EventState, action:EventAction) => EventState = (state:EventState, action:EventAction) => {
  switch (action.type) {
    case EventActions.EVENTS_INIT:
      console.log("Init events", action.events?.length);
      return { ...state, events: action.events, currentEvent: action.events?.length ? action.events[0] : null, loaded: true};
    case EventActions.CHANGE_EVENT:
      console.log("Set current event", action.event);
      return { ...state, currentEvent: action.event};
    default:
      return state;
  }
};


export const EventsDataContext = React.createContext<EventContextState>(initialEventState);
export const EventProvider: React.FC<{}> =  (props) => {
  const [state, dispatch] = useReducer(eventReducer, initialEventState.state);
  const userContextData = useContext(UserContext);
  const userId = userContextData.state.user?.id;

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  useEffect(() => {
    async function execute(){
      if(false && process.env.REACT_APP_MOCKED_AUTH === 'true') {
        dispatch({ type: EventActions.EVENTS_INIT, events: [{id: 1, title: 'Local Development', startDateTime:'', eventTypeId:1, eventTypeName:'nunta', eventTypeCode: 'nunta'}], loaded: true});
      } else {
        const result = await get(`/api/react/events/filter/${userId}`);
        dispatch({ type: EventActions.EVENTS_INIT, events: result.data, loaded: true});
      }
    }
    execute();
  }, []);

  return (
    <EventsDataContext.Provider value={contextValue}>
      {props.children}
    </EventsDataContext.Provider>
  )
};
