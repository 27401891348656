import React, { useState, useEffect, useContext } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import { EventsDataContext } from "../../contexts/EventContext";
import { patch } from "../../utils/ApiUtils";

import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Accordion from '@material-ui/core/Accordion';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from "@material-ui/core/Tooltip";
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FaceIcon from '@material-ui/icons/Face';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import DraftsIcon from '@material-ui/icons/Drafts';
import Add from "@material-ui/icons/Add";
import DescriptionIcon from '@material-ui/icons/Description';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, Fab } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import {Chip, Icon} from "@material-ui/core";

import styles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";
import OutlinedDiv from "../../components/Textfield/OutlinedDiv.js";
import CollapsableRow from "./CollapsableRow.js";
import AutocompleteField from "./AutocompleteField.js";
import { AddInvite } from "./AddInvite";

import { Tag } from "../../types/Tag";
import { Guest } from "../../types/Guest";
import { Event } from "../../types/Event";

const useStyles = makeStyles(styles as any);

enum InviteStatus {
  Unsent = "unsent",
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected"
}

const TABLE_NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const removeDuplicates = (tagsList:Tag[], tagsToRemove:{id:number}[]) => {
  const tagIdsToRemove:number[] = tagsToRemove?.map(tag => tag.id) || [];
  const results = tagsList?.filter(function(listObj:Tag) {
    return tagIdsToRemove.indexOf(listObj.id)===-1;
  });
  return results;
}

export const Invites: React.FC<{}> = (props) => {
  const classes = useStyles();
  const mobileWindowSize = useMediaQuery('(max-width:693px)');
  const smallMobileWindowSize = useMediaQuery('(max-width:500px)');
  const [guests, setGuests] = useState<Guest[]>([]);
  const [status, setStatus] = useState<string>('ready');
  const eventsData = useContext(EventsDataContext);
  const [selectedGuestId, setSelectedGuestId] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openRsvpDetails, setOpenRsvpDetails] = useState(false);
  const [groupTags, setGroupTags] = useState<any>([]);
  const [groupTagsList, setGroupTagsList] = useState<Tag[]>([]);
  const [dbGroupTagsList, setDbGroupTagsList] = useState<Tag[]>([]);
  const [individualTags, setIndividualTags] = useState<Tag[]>([]);
  const [individualTagsList, setIndividualTagsList] = useState<Tag[]>([]);
  const [dbIndividualTagsList, setDbIndividualTagsList] = useState<Tag[]>([]);
  const initialNewGuest: Guest = {name:"", eventId:eventsData.state.currentEvent?.id as number, numberOfPersons:1, status:"pending", phone: '', email:'', groupTags: [], individualTags: [], acceptedTerms: 0};
  const [selectedGuest, setSelectedGuest] = useState<any>(initialNewGuest);
  const [isAddGuestModalOpen, setIsAddGuestModalOpen] = useState<boolean>(false);

  const handleClickForGuest = (guestId: string) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      console.log(`Selected invite id ${guestId}`);
      setSelectedGuestId(guestId);
      setSelectedGuest(guests.find(guest => guest.id === guestId));
      setAnchorEl(event.currentTarget);
    };
  };

  const handleGuestAccepted = async () => {
    console.log(`Confirm guest with id ${selectedGuestId}`);
    const payload = {
      guestId: selectedGuestId,
      status: InviteStatus.Accepted
    };
    const response = await patch('/api/react/guests/updateStatus',payload);
    console.log(`The result of the confirmation ${response.data.guestStatusUpdated}`);
    if(response.data.guestStatusUpdated){
      const selectedGuest = guests.find(element => element.id == selectedGuestId);
      selectedGuest!.status = InviteStatus.Accepted;
      handleClose();
    }
  };

  const handleGuestRejected = async () => {
    console.log(`Confirm guest with id ${selectedGuestId}`);
    const payload = {
      guestId: selectedGuestId,
      status: InviteStatus.Rejected
    };
    const response = await patch('/api/react/guests/updateStatus',payload);
    console.log(`The result of the confirmation ${response.data.guestStatusUpdated}`);
    if(response.data.guestStatusUpdated){
      const selectedGuest = guests.find(element => element.id == selectedGuestId);
      selectedGuest!.status = InviteStatus.Rejected;
      selectedGuest!.tableNo = null;
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEdit = () => {
    const guest = guests.find((el) => el.id == selectedGuestId);
    setSelectedGuest(guest);
    setGroupTags(guest!.groupTags || []);
    setIndividualTags(guest!.individualTags || []);
    setGroupTagsList(removeDuplicates(dbGroupTagsList, guest!.groupTags));
    setIndividualTagsList(removeDuplicates(dbIndividualTagsList, guest!.individualTags || []));
    setOpenEdit(true);
    handleClose();
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  }

  const handleOpenRsvpDetails = (guestId: string) => {
    let guest = guests.find(el => el.id == guestId);
    setSelectedGuest(guest);
    setOpenRsvpDetails(true);
  }

  const handleMenuOpenRsvpDetails = () => {
    setOpenRsvpDetails(true);
    handleClose();
  }

  const handleCloseRsvpDetails = () => {
    setOpenRsvpDetails(false);
  }

  useEffect(() => {
    setStatus('loading');
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guests/filter/${eventsData.state.currentEvent?.id}`)
        .then(response => response.json())
        .then(response => {
          setGuests(response);
          setStatus('ready');
        })
        .catch(error => setStatus('error'));
  }, [eventsData.state.currentEvent?.id]);

  useEffect(() => {
    setStatus('loading');
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guestTags`)
        .then(response => response.json())
        .then(response => {
          setDbGroupTagsList(response.groupTags);
          setDbIndividualTagsList(response.individualTags);
          setStatus('ready');
        })
        .catch(error => setStatus('error'));
  }, []);

  const editGuestHandler = async () => {
    selectedGuest.groupTags = groupTags;
    selectedGuest.individualTags = individualTags;
    const payload = JSON.stringify(selectedGuest);
    const response = await patch('/api/react/guests/edit',payload);
    console.log(`The result of the edit confirmation ${response.data.guestUpdated}`);
    if(response.data.guestUpdated){
      const editedGuest = guests.find(element => element.id == response.data.rsvp.id);
      const data = response.data.rsvp;
      editedGuest!.name = data!.name;
      editedGuest!.status = data!.status;
      editedGuest!.numberOfPersons = data!.numberOfPersons;
      editedGuest!.tableNo = data!.tableNo;
      editedGuest!.phone = data!.phone;
      editedGuest!.email = data!.email;
      editedGuest!.groupTags = data!.groupTags;
      editedGuest!.individualTags = data!.individualTags;
      handleCloseEdit();
    }
  };

  const handleEditGuestChange = (event: React.ChangeEvent<any>): void  => {
    setSelectedGuest({
      ...selectedGuest,
      [event.target.name]: event.target.value,
    });
    console.log(`Edit guest value`, selectedGuest.numberOfPersons);
  };

  const handleGroupTagsChange = (event:React.ChangeEvent<HTMLInputElement>, values:any): void  => {
    const lastTag = values?.pop();
    setGroupTagsList(groupTagsList.filter(item => item.name !== lastTag?.name));
    setGroupTags([...groupTags, lastTag]);
  };

  const handleIndividualTagsChange = (event:React.ChangeEvent<HTMLInputElement>, values:any): void  => {
    const lastTag = values.pop();
    setIndividualTagsList(individualTagsList.filter(item => item.name !== lastTag.name));
    setIndividualTags([...individualTags, lastTag]);
  };

  const handleDeleteGroupTag = (tagToDelete:Tag) => () => {
    setGroupTags((tags:Tag[]) => tags.filter((tag) => tag.id !== tagToDelete.id));
    setGroupTagsList([...groupTagsList, tagToDelete]);
  };

  const handleDeleteIndividualTag = (tagToDelete:Tag) => () => {
    setIndividualTags((tags:Tag[]) => tags.filter((tag) => tag.id !== tagToDelete.id));
    setIndividualTagsList([...individualTagsList, tagToDelete]);
  };

  const deleteInvites: () => void = () => {
    console.log(`Delete invite with id ${selectedGuestId}`);
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/react/guests/delete/${selectedGuestId}`, 
      {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        }
      })
      .then(response => response.json())
      .then(responseJson => {
        if(responseJson.guestDeleted){
          console.log(`Guest ${selectedGuestId} deleted`);
          const newGuests:Guest[] = []; 
          for (let guest of guests) {
            if(guest.id != selectedGuestId){
              newGuests.push(guest);
            }
          }
          setGuests(newGuests); 
        } else {
          console.warn(`No guest deleted for id ${selectedGuestId}`);
        }
      })
      .catch(error => setStatus('error'));
  };

  const statusCellRenderer = (guest: Guest) => {
    switch (guest!.status) {
      case 'accepted':
        return <Chip style={{backgroundColor: "#88ca5f"}} icon={<SentimentVerySatisfiedIcon style={{ color: "#fff" }} />} label="Da" variant="outlined" onClick={guest.acceptedTerms ? () => handleOpenRsvpDetails(guest.id) : undefined} className={guest.acceptedTerms ? classes.coloredOutline : ''} />;
      case 'rejected':
        return <Chip style={{backgroundColor: "#ec7878"}} icon={<SentimentVeryDissatisfiedIcon style={{ color: "#fff" }} />} label="Nu" variant="outlined" onClick={guest.acceptedTerms ? () => handleOpenRsvpDetails(guest.id) : undefined} className={guest.acceptedTerms ? classes.coloredOutline : ''} />;
    }
    return <Chip style={{backgroundColor: "#7c858d"}} icon={<HelpOutlineIcon style={{ color: "#fff" }} />} label="Pending" variant="outlined" />;
  };

  const defaultText = (
    <TableRow>
      <TableCell colSpan={5} className={classes.defaultText}>
        <Typography variant='body2'>Ai 0 invitați.</Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <div>
        <Card className={classes.card}>
          <CardHeader color="aluminium">
            <h4 className={classes.cardTitleWhite}>{eventsData.state.currentEvent?.title}</h4>
            <p className={classes.cardCategoryWhite}>
              {guests.length === 1 ? "1 invitat până acum" : guests.length > 0 ? guests.length + " invitați până acum" : "Niciun invitat încă"}
            </p>
          </CardHeader>
          <CardBody>
            {mobileWindowSize ? 
              (
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell width="auto" className={classes.pl28}>Invitat</TableCell>
                      <TableCell width={74}>Confirmare</TableCell>
                      { smallMobileWindowSize ? 
                        <TableCell width={10} className={classes.iconHeaderCell}><MoreVertIcon/></TableCell> : 
                        <TableCell width={47}>Acțiuni</TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { status === 'ready' && 
                      (guests.length ? guests.map((guest:Guest) => {
                        return (
                          <CollapsableRow
                            key={`collapsable-row-${guest.id}`}
                            guest={guest}
                            classes={classes}
                            handleClickForGuest={handleClickForGuest}
                            handleOpenRsvpDetails={handleOpenRsvpDetails}  
                          />
                        );
                      }) : defaultText)
                    }
                  </TableBody>
                </Table>
               ) : 
              (
                <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>Invitat</TableCell>
                    <TableCell width={74}>Confirmare</TableCell>
                    <TableCell>Grupuri</TableCell>
                    <TableCell>Etichete</TableCell>
                    <TableCell width={47}>Acțiuni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { status === 'ready' && 
                    (guests.length ? guests.map((guest:Guest) => {
                      const labelId = `checkbox-list-label-${guest.id}`;
                      return (
                        <TableRow key={labelId} className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <span className={classes.cellTitle}>{guest.name}</span><br/>
                            <span className={classes.cellText}>
                              {guest?.numberOfPersons ? guest.numberOfPersons === 1 ? '1 persoană' : guest?.numberOfPersons + ' persoane' : ''}
                              {guest?.tableNo ? ` la Masa ${guest.tableNo}` : ''}
                            </span>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {statusCellRenderer(guest)}
                          </TableCell>
                          <TableCell className={classes.tableCellIcons}>
                            {guest.groupTags ? guest!.groupTags.map((tag:Tag) => {
                              return (
                                <Chip
                                  key={tag.id}
                                  icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                                  label={tag.name}
                                  variant="outlined"
                                />
                              );
                            }) : ''}
                          </TableCell>
                          <TableCell className={classes.tableCellIcons}>
                            {guest.individualTags ? guest.individualTags.map((tag:Tag) => {
                              return (
                                <Chip
                                  key={tag.id}
                                  icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                                  label={tag.name}
                                  variant="outlined"
                                />
                              );
                            }) : ''}
                          </TableCell>
    
                          <TableCell className={classes.tableActions}>
                            <Tooltip
                                id="tooltip-top"
                                title="Acțiuni"
                                placement="top"
                                classes={{tooltip: classes.tooltip}}
                            >
                              <IconButton aria-controls="simple-menu" aria-haspopup="true"
                                  onClick={handleClickForGuest(guest.id)}>
                                <MoreVertIcon/>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    }) : defaultText)
                  }
                </TableBody>
              </Table>
              )
            }
          </CardBody>
        </Card>
        <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.editMenu}
              >
                <MenuItem onClick={handleGuestAccepted}>
                  <ListItemIcon>
                    <SentimentVerySatisfiedIcon style={{ color: "rgba(0, 0, 0, 0.8)" }} />
                  </ListItemIcon>
                  <ListItemText primary="Confirmă" />
                </MenuItem>
                <MenuItem onClick={handleGuestRejected}>
                  <ListItemIcon>
                    <SentimentVeryDissatisfiedIcon style={{ color: "rgba(0, 0, 0, 0.8)" }}/>
                  </ListItemIcon>
                  <ListItemText primary="Infirmă" />
                </MenuItem>
                <MenuItem onClick={handleOpenEdit}>
                  <ListItemIcon>
                    <EditIcon style={{ color: "rgba(0, 0, 0, 0.8)" }}/>
                  </ListItemIcon>
                  <ListItemText primary="Editează" />
                </MenuItem>
                <DeleteButton buttonText="Șterge"
                  confirmTitle="Șterge invitați" 
                  confirmText={`Cu siguranță dorești ștergerea invitatului/ei ${selectedGuest?.name}?`}
                  confirmedDeleteHandler={deleteInvites}
                  modalOpenHandler={handleClose}
                  />
                <MenuItem onClick={handleMenuOpenRsvpDetails}>
                  <ListItemIcon>
                    <DescriptionIcon style={{ color: "rgba(0, 0, 0, 0.8)" }}/>
                  </ListItemIcon>
                  <ListItemText primary="Detalii" />
                </MenuItem>
      </Menu>
      <Dialog
        maxWidth="sm"
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title">Editează confirmarea</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Accordion className="dialog-content">
            <AccordionDetails className={classes.details}>
              <GridContainer>
                <GridItem xs={12} sm={6} className={classes.dialogGridItem}>
                  <TextField fullWidth margin="normal" id="standard-basic" name="name" label="Nume" value={selectedGuest?.name} onChange={handleEditGuestChange} />
                </GridItem>
                <GridItem xs={6} sm={3} className={classes.dialogGridItem}>
                  <FormControl variant="standard" fullWidth margin="normal">
                    <InputLabel id="demo-simple-select-standard-label">Nr. persoane</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selectedGuest?.numberOfPersons}
                      onChange={handleEditGuestChange}
                      name="numberOfPersons"
                      label="Nr. persoane"
                    >
                      {[...Array(10)].map((x, i) => <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>)}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={3} className={classes.dialogGridItem}>
                  {/* <TextField fullWidth margin="normal" id="standard-basic" name="tableNo" label="Masa" type="number" value={selectedGuest.tableNo || ""} onChange={handleEditGuestChange} /> */}
                  <FormControl variant="standard" fullWidth margin="normal">
                      <InputLabel id="demo-simple-select-standard-label">Nr. masă</InputLabel>
                      <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectedGuest?.tableNo || ""}
                          onChange={handleEditGuestChange}
                          name="tableNo"
                          label="Nr. masă"
                      >
                      {TABLE_NUMBERS.map((tableNumber: number) => <MenuItem key={tableNumber} value={tableNumber}>{tableNumber < 10 ? `0${tableNumber}` : tableNumber}</MenuItem>)}
                      </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={5} className={classes.dialogGridItem}>
                  <TextField fullWidth margin="normal" id="standard-basic" name="phone" label="Telefon" value={selectedGuest?.phone} onChange={handleEditGuestChange} />
                </GridItem>
                <GridItem xs={12} sm={7} className={classes.dialogGridItem}>
                  <TextField fullWidth margin="normal" id="standard-basic" name="email" label="Email" value={selectedGuest?.email} onChange={handleEditGuestChange} />
                </GridItem>
                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                  <OutlinedDiv label="Grupuri" classes={`${classes.tableCellIcons} ${classes.tableCellIconsEdit}`}>
                    {groupTags ? groupTags.map((tag:Tag) => {
                      return (
                        <Chip
                          key={tag.id}
                          icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                          label={tag.name}
                          variant="outlined"
                          onDelete={handleDeleteGroupTag(tag)}
                        />
                      );
                    }) : ''}
                    <AutocompleteField classes={classes} tagsList={groupTagsList} handleChange={handleGroupTagsChange}/>
                  </OutlinedDiv>
                </GridItem>
                <GridItem xs={12} sm={12} className={classes.dialogGridItem}>
                  <OutlinedDiv label="Etichete" classes={`${classes.tableCellIcons} ${classes.tableCellIconsEdit}`}>
                    {individualTags ? individualTags.map((tag:Tag) => {
                      return (
                        <Chip
                          key={tag.id}
                          icon={<Icon style={{ color: "#fff" }}>{tag.iconCode}</Icon>}
                          label={tag.name}
                          variant="outlined"
                          onDelete={handleDeleteIndividualTag(tag)}
                        />
                      );
                    }) : ''}
                    <AutocompleteField classes={classes} tagsList={individualTagsList} handleChange={handleIndividualTagsChange}/>
                  </OutlinedDiv>
                </GridItem>
              </GridContainer>
            </AccordionDetails>
            <Divider />
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Renunță</Button>
          <Button onClick={editGuestHandler} autoFocus>
            Salvează
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={openRsvpDetails}
        onClose={handleCloseRsvpDetails}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalii confirmare
          <Typography variant='body2'>{selectedGuest?.createdAt}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Accordion className="dialog-content">
            <AccordionDetails className={classes.details}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <TextField fullWidth margin="normal" id="standard-basic" label="Nume" value={selectedGuest?.name} inputProps={{ readOnly: true }} variant="outlined" InputLabelProps={{ shrink: true }} />
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <TextField fullWidth margin="normal" id="standard-basic" label="Nr. persoane" type="number" value={selectedGuest?.numberOfPersons || ''} inputProps={{ readOnly: true }} variant="outlined" InputLabelProps={{ shrink: true }} />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField fullWidth margin="normal" id="standard-basic" label="Telefon" value={selectedGuest?.phone || ''} inputProps={{ readOnly: true }} variant="outlined" InputLabelProps={{ shrink: true }} />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField fullWidth margin="normal" id="standard-basic" label="Email" value={selectedGuest?.email} inputProps={{ readOnly: true }} variant="outlined" InputLabelProps={{ shrink: true }} />
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <TextField fullWidth margin="normal" multiline id="standard-basic" label="Mesaj" value={selectedGuest?.message || ''} inputProps={{ readOnly: true }} variant="outlined" InputLabelProps={{ shrink: true }} />
                </GridItem>
              </GridContainer>
            </AccordionDetails>
            <Divider />
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRsvpDetails}>
            Închide
          </Button>
        </DialogActions>
      </Dialog>

      <Fab color="primary" aria-label="add" className={classes.addBtn} onClick={() => setIsAddGuestModalOpen(!isAddGuestModalOpen)}>
        <Add />
      </Fab>
      <AddInvite
        isDialogOpened={isAddGuestModalOpen}
        handleCloseDialog={() => setIsAddGuestModalOpen(false)}
        eventId={eventsData.state.currentEvent?.id}
        TABLE_NUMBERS={TABLE_NUMBERS}
        dbGroupTagsList={dbGroupTagsList}
        dbIndividualTagsList={dbIndividualTagsList}
        setGuests={setGuests}
        setStatus={setStatus}
      />
    </div>
  );
}
